import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GlobalAuthGuard } from '@core/services/global-guard/auth-guard';
import { environment } from '@environment/environment';
import { RoutesPaths } from '@shared/domain/routes-paths';

const canActivateValue = environment.production ? [GlobalAuthGuard] : [];

export const routes: Routes = [
  { path: '', redirectTo: RoutesPaths.PERSONAL_DATA, pathMatch: 'full' },
  {
    path: RoutesPaths.PERSONAL_DATA,
    loadChildren: () =>
      import('./features/personal-data/personal-data.module').then((m) => m.PersonalDataModule),
    pathMatch: 'full',
  },
  {
    path: RoutesPaths.AUTHENTICATION,
    loadChildren: () =>
      import('./features/authentication/authentication.module').then((m) => m.AuthenticationModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.RETURN_OFFER,
    loadChildren: () =>
      import('./features/loader-return-offer/loader-return-offer.module').then(
        (m) => m.LoaderReturnOfferModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.FIRTS_EVALUATE,
    loadChildren: () =>
      import('./features/loader-evaluate/loader-evaluate.module').then(
        (m) => m.LoaderEvaluateModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.FAILED_PROCESS,
    loadChildren: () =>
      import('./features/failed-process/failed-process.module').then((m) => m.FailedProcessModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.UNAUTHORIZED_ERROR,
    loadChildren: () =>
      import('./features/unauthorized-error/unauthorized-error.module').then(
        (m) => m.UnauthorizedErrorModule
      ),
    pathMatch: 'full',
    // canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.RESGISTRY_ERROR,
    loadChildren: () =>
      import('./features/registry-error/registry-error.module').then((m) => m.RegistryErrorModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.UNAUTHORIZED,
    loadChildren: () =>
      import('./features/unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.ADDITIONAL_DATA,
    loadChildren: () =>
      import('./features/additional-data/additional-data.module').then(
        (m) => m.AdditionalDataModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.SALES_AGENT_LOGIN,
    loadChildren: () =>
      import('./features/sales-agent-login/sales-agent-login.module').then(
        (m) => m.SalesAgentLoginModule
      ),
    pathMatch: 'full',
  },
  {
    path: RoutesPaths.PRODUCTS_SELECTION,
    loadChildren: () =>
      import('./features/products-selection/products-selection.module').then(
        (m) => m.ProductsSelectionModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.SECOND_EVALUATE,
    loadChildren: () =>
      import('./features/loader-evaluate-full/loader-evaluate-full.module').then(
        (m) => m.LoaderEvaluateFullModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.PRODUCT_CROSSING,
    loadChildren: () =>
      import('./features/product-crossing/product-crossing.module').then(
        (m) => m.ProductCrossingModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.PRODUCT_CROSSING_WITHOUT_OFFER,
    // eslint-disable-next-line max-len
    loadChildren: () =>
      import(
        './features/product-crossing-without-offer/product-crossing-without-offer.module'
      ).then((m) => m.ProductCrossingWithoutOfferModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.OFFER,
    loadChildren: () =>
      import('./features/with-offer/with-offer.module').then((m) => m.WithOfferModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.NO_TC_OFFER,
    loadChildren: () =>
      import('./features/no-tc-offer/no-tc-offer.module').then((m) => m.NoTcOfferModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.BIRTHPLACE_LOCAL,
    loadChildren: () =>
      import('./features/birthplace-local/birthplace-local.module').then(
        (m) => m.BirthplaceLocalModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.BIRTHPLACE_FOREIGNER,
    loadChildren: () =>
      import('./features/birthplace-foreigner/birthplace-foreigner.module').then(
        (m) => m.BirthplaceForeignerModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.JOB_INFO,
    loadChildren: () => import('./features/job-info/job-info.module').then((m) => m.JobInfoModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.PEP_FATCA,
    loadChildren: () =>
      import('./features/pep-fatca/pep-fatca.module').then((m) => m.PepFatcaModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.PAYMENT_DATE,
    loadChildren: () =>
      import('./features/payment-date/payment-date.module').then((m) => m.PaymentDateModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.PAC_PURPOSE,
    loadChildren: () =>
      import('./features/pac-purpose/pac-purpose.module').then((m) => m.PacPurposeModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.PRODUCT_READY,
    loadChildren: () =>
      import('./features/product-ready/product-ready.module').then((m) => m.ProductReadyModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.UPLOAD_ID,
    loadChildren: () =>
      import('./features/upload-id/upload-id.module').then((m) => m.UploadIDModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.PRINT_DOCUMENTS,
    loadChildren: () =>
      import('./features/print-documents/print-documents.module').then(
        (m) => m.PrintDocumentsModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.CREATE_WEB_PASSWORD,
    loadChildren: () =>
      import('./features/create-web-password/create-web-password.module').then(
        (m) => m.CreateWebPasswordModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.WEB_PASSWORD_STATE,
    loadChildren: () =>
      import('./features/loader-web-password-state/loader-web-password-state.module').then(
        (m) => m.LoaderWebPasswordStateModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.RESUME_APPLICATION,
    loadChildren: () =>
      import('./features/loader-resume-application/loader-resume-application.module').then(
        (m) => m.LoaderResumeApplicationModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.USE_YOUR_PRODUCT,
    loadChildren: () =>
      import('./features/use-your-product/use-your-product.module').then(
        (m) => m.UseYourProductModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.FINISHED_PROCESS,
    loadChildren: () =>
      import('./features/finished-process/finished-process.module').then(
        (m) => m.FinishedProcessModule
      ),
    pathMatch: 'full',
    // canActivate: canActivateValue
  },
  {
    path: RoutesPaths.GO_TO_BRANCH,
    loadChildren: () =>
      import('./features/go-to-branch/go-to-branch.module').then((m) => m.GoToBranchModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.CA_PRODUCT_FINISHED,
    loadChildren: () =>
      import('./features/ca-product-finished/ca-product-finished.module').then(
        (m) => m.CaProductFinishedModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.CONTINGENCY_SIGNATURE,
    loadChildren: () =>
      import('./features/contingency-signature/contingency-signature.module').then(
        (m) => m.ContingencySignatureModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  // {
  //   path: RoutesPaths.SIGNATURE_REGISTRATION,
  //   loadChildren: () => import('./features/signature-registration/signature-registration.module').then(m => m.SignatureRegistrationModule),
  //   pathMatch: 'full',
  //   canActivate: canActivateValue
  // },
  {
    path: RoutesPaths.INSURANCE_SELECTION,
    loadChildren: () =>
      import('./features/insurance-selection/insurance-selection.module').then(
        (m) => m.InsuranceSelectionModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.CREATE_PRODUCT,
    loadChildren: () =>
      import('./features/create-product/create-product.module').then((m) => m.CreateProductModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.UPDATE_GMF,
    loadChildren: () =>
      import('./features/update-gmf/update-gmf.module').then((m) => m.UpdateGmfModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.PRODUCT_RESUME,
    loadChildren: () =>
      import('./features/product-resume/product-resume.module').then((m) => m.ProductResumeModule),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.SIGNATURE_RESUMEN,
    loadChildren: () =>
      import('./features/signature-resume/signature-resume.module').then(
        (m) => m.SignatureResumeModule
      ),
    pathMatch: 'full',
    canActivate: canActivateValue,
  },
  {
    path: RoutesPaths.FACE_ID,
    loadChildren: () => import('./features/face-id/face-id.module').then((m) => m.FaceIdModule),
    pathMatch: 'full',
    canActivate: canActivateValue
  },
  {
    path: RoutesPaths.PERSONAL_ADDRESS,
    loadChildren: () => import('./features/personal-address/personal-address.module').then(m => m.PersonalAddressModule),
    pathMatch: 'full',
    canActivate: canActivateValue
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
