<!-- <div id="modal-info" class="modal-info" [hidden]="hidden">
  <div class="backdrop" #simplebar>
    <div class="modal-content animated" id="modalInformativo"
    [ngClass]="{'fadeOutDown': !hidden, 'fadeInUp': hidden}">
    <div class="header">
      <div class="close" (click)="toggle('btn-close')"><img src="{{staticAssetsUrl}}assets/img/icn-cross.svg" class="close-btn" alt="" /></div>
      <img class='logo-falabella-modal' alt='logo' src='{{staticAssetsUrl}}assets/img/logo-banco-falabella.svg'>
    </div>
    <ng-content class="body-content"></ng-content>
  </div>
  </div>
</div> -->

<div id="modal-info" class="modal-info" [hidden]="hidden">
  <div class="white-content" #simplebar>
    <div class="header">
      <div class="close" (click)="toggle('btn-close')"><img src="{{staticAssetsUrl}}assets/img/icn-cross.svg" class="close-btn" alt="" /></div>
      <!-- <img class='logo-falabella-modal' alt='logo' src='{{staticAssetsUrl}}assets/img/logo-banco-falabella.svg'> -->
      <p>Comparar tarjetas</p>
    </div>
    <div class="body-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>

