import { Api } from './api/api.prd';
import { App } from './app/app.local';
import { Common } from './common/common.local';

export const environment: any = {
  name: 'prod',
  country: 'ONBCO_WEB',
  useAuthenticate: true,
  production: true,
  api: Api.apis,
  common: Common.commons,
  token: {
    loginUrl: Api.apis.service_login,
    revokeUrl: Api.apis.service_revoke_token,
    refreshUrl: Api.apis.service_refresh_token,
    graceTime: 30,
  },
  app: App.confs,
  cipher: {
    useInRequest: true,
    useInResponse: true,
    disableInStorage: false,
    storageKey: 'f8220093c8cc7faa9d6eec88827cf0c9',
    publicKey:
      '-----BEGIN PUBLIC KEY-----\n' +
      'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr/uJqitbcJG/9jLH7lDu\n' +
      'X/jg5Q2VxDh4iwBtQvPigZiZRuChuowU9XmW+XS6J8N1lXcpe9vhErBFKX/9ELAa\n' +
      'r3PnIYVnjtVqLxro2f0eqocdWw1AN2lx01SqxiqmdHEZCsj9mEQ6sYNcNdeH6G/9\n' +
      'GNk/3Y7c0H90Gi/+vWYN1sVr+LJR5CU/emdO1W2FaQ8T2+GWhyNVOkDZEx4dTsDE\n' +
      'cX0PmGUwxPv+2gZb99fNxhwKV4Vq5Mt9ACAl3vrhUH33kZPuw3miJHKXvlw+9kuU\n' +
      'uOFJFOga/D9FMfCrYcCqlBuzf2T6w0Q19Ik+uyo3zLn3YFN1ufBfeAV51Z0kKBiT\n' +
      'zwIDAQAB\n' +
      '-----END PUBLIC KEY-----',
  },
  flagr: {
    url: 'https://flagr.fif.tech/api',
    singleEvaluation: '/flag/evaluation/v1/single',
    batchEvaluation: '/flag/evaluation/v1/batch',
    pubKey:
      '-----BEGIN PUBLIC KEY-----' +
      'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApkALLhocuyoPO/MgvnsF' +
      'mBcX+z0HYoSQnLKS9E+J3LaVOlLhgQRBs5Ygr/1mFXArtKuIMimDG8GCi9r8YdYn' +
      't57FzBZD4ddICmFVsbQJmmWrJVoF7t6DFMzK4EKP5KXJ3b2PDdKP7WTi4ecJDry5' +
      'GTHE/CCk/AxeRDdSYCYwDCoMmzgCcpqouQxcgJ2LUWQdENjDq+jzHzOdm2v56chO' +
      '4HpeBBIYLYr+ZwBfHQj8LgpdxDDxw8nsfRBgaebTK2jQrAiiYaqjy0tHrrpxxtQL' +
      'bagRDji2kIfeuAleLHaX2fZXw3PlI8KiWRd36XDn4kkfnz5XL8Zvk71nV3XIiEIv' +
      'XwIDAQAB' +
      '-----END PUBLIC KEY-----',
    flags: {
      multiproduct: {
        flagID: 81,
        flagKey: 'rout_bfco_onbording_multiproducto_autoasistido',
      },
      faceId: {
        flagID: 198,
        flagKey: 'rout_bfco_onbording_face_id_flow',
      },
      obligatory_insurance: {
        flagID: 203,
        flagKey: 'rout_bfco_onboarding_obligatory_insurance',
      }
    },
  },
  CAPTCHA_ENABLE: true,
  CAPTCHA_KEY: '6Le_MnkUAAAAABiPKnOPh-7-65wFvwS4Cmx8-bGC',
  staticAssetsUrl: '',
  keysToCleanLocalStorage: [
    'is-ca-flow',
    'content',
    'personal-data',
    'isCallcenterUser',
    'isInPersonChannel',
    'person',
    'sessionId',
    'expires_in',
    'expires_in_date',
    'access_token',
    'refresh_token',
    'username',
    'loginType',
    'signature-retries',
    'extraField',
    'additional-data',
    'employment-info',
    'intentTypeAuthentication',
    'isClientByType',
    'registry-success',
    'second',
    'requested-products',
    'is-tc',
    'is-pac',
    'is-ca',
    'insurance-selection',
    'moreInfo',
    'is-fraud',
    'account-number',
    'password-created',
    '_hjid',
    'randomseed',
    'flowSessionId',
    'page-loader-evaluate',
    'page-loader-evaluate-full',
    'prod',
  ],
  datadog: {
    applicationId: '4f61b294-11b9-4783-8e38-d0e7cbd968af',
    clientToken: 'pubdc937d0fc97eeb7ec9baad713c360800',
    site: 'datadoghq.com',
    service: 'banco-co-onboarding-tarjeta-prod',
    env: 'prod',
    sessionSampleRate: 10,
    sessionReplaySampleRate: 10,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  },
  dataDogConfigName: 'configTarjetaProd',
  faceId: {
    INIT: {
      API_KEY: 'e7trztPHnK2ZOmaKjs9dz70dYBFVC5Uo3Zq0Bgws',
      CLIENT_ID: '7f9voufbt9a315adi9m849h38g',
      CLIENT_SECRET: 'bi8e6r583c9n3ro4ut7t48glnreptdl0p58senqp9u0knep938',
      PRODUCTION: true,
      ENTITY_ID: '29', // Identificador de la entidad para activar la personalización, si no desea activar la personalización no configurarlo.,
    },
    PROCESS_TYPE: 'ENR',
    CHANNEL: 'WEB_CLIENT',
    ACCEPT_TYC_EXTERNAL: false,
    OTP_IN_TERNAL: true,
    DOCUMENT_TYPE: 'CC',
    PHONE_INDICATIVE: '57',
    APP_IDENTIFIER: 'solicitudes.bancofalabella.com.co',
    PROCEDURE_ID: 68,
  },
};
