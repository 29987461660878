/* eslint-disable max-len */
export class Api {
  public static apis: any = {
    service_authenticate: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/assessment/type',
    service_login: 'https://banco-co-onboarding-qa.fif.tech/onboarding/acquisition/login/validate/',
    service_refresh_token: 'https://banco-co-onboarding-qa.fif.tech/onboarding/acquisition/login/refresh/',
    service_revoke_token: 'https://banco-co-onboarding-qa.fif.tech/onboarding/acquisition/login/revoke/',
    service_personal_data: 'https://banco-co-onboarding-qa.fif.tech/onboarding/acquisition/application/verify/',
    service_intention: 'https://banco-co-onboarding-qa.fif.tech/onboarding/acquisition/application/intention/',
    service_evaluate_person: 'https://banco-co-onboarding-qa.fif.tech/onboarding/assessment/evaluate/verify/first',
    service_application_create: 'https://banco-co-onboarding-qa.fif.tech/onboarding/assessment/application/create',
    service_application_return_offer: 'https://banco-co-onboarding-qa.fif.tech/onboarding/assessment/application/return-offer',
    service_application_update_application: 'https://banco-co-onboarding-qa.fif.tech/onboarding/assessment/application/update-application',
    service_second_evaluate_person: 'https://banco-co-onboarding-qa.fif.tech/onboarding/assessment/evaluate/verify/second',
    service_more_job_info: 'https://banco-co-onboarding-qa.fif.tech/onboarding/assessment/evaluate/verify/more-job-info',
    service_content_ful_products: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/contentful/v1/products',
    service_content_ful_data_dog_config: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/contentful/v1/ocoDatadogConfig',
    service_content_ful_landing: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/contentful/v1/ocoLandingBeneficios',
    service_content_ful_legal_terms: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/contentful/v1/ocoLegalTerms',
    service_external_authenticate_generate: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/assessment/external/generate',
    service_verify_first_factor: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/assessment/internal/first-factor/validation',
    service_verify_second_factor_generation: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/assessment/internal/second-factor/otp/generation',
    service_verify_second_factor_validation: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/assessment/internal/second-factor/otp/validation',
    service_front_log: {
      endpoint: 'https://banco-co-onboarding-qa.fif.tech/onboarding/general/front-log/v1/',
      disableConsole: false,
      disableHttp: true
    },
    service_external_authenticate_validate: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/assessment/external/validate',
    service_external_authenticate_simswap: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/assessment/external/simswap',
    service_external_authenticate_registry: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/assessment/external/registraduria/setRequestByDoc',
    service_external_authenticate_registry_getProcess: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/assessment/external/registraduria/getProcess',
    service_linkage_occupational_data: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/client/v1/job-info',
    service_linkage_personal_data: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/client/v1/personal-data',
    service_linkage_pep_fatca: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/client/v1/pep-fatca-info',
    service_linkage_payment_date: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/client/v1/payment-date',
    service_linkage_pac_info: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/client/v1/pac-info',
    service_client_create: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/client/v1/create-client',
    service_application_clone: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/client/v1/clone-application',
    service_linkage_document_upload: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/document/v1/upload-id',
    service_linkage_document_save: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/document/v1/save',
    service_linkage_generate_otp: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/linkage/internal/second-factor/otp/generation',
    service_linkage_validate_otp: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/linkage/internal/second-factor/otp/validation',
    service_linkage_product_create: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/product/v1/create',
    service_linkage_product_create_multi: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/product/v1/create-product',
    service_linkage_signature_sign: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/signature/v1/apply',
    service_linkage_insurance_suscription: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/insurance/v1/subscription',
    service_fingerprint: 'https://banco-co-onboarding-qa.fif.tech/onboarding/assessment/risk/fingerprint',
    service_activation_pass_status: 'https://banco-co-onboarding-qa.fif.tech/onboarding/activation/password/status',
    service_activation_pass_create: 'https://banco-co-onboarding-qa.fif.tech/onboarding/activation/password/create',
    service_get_customer_integracion_retail: 'https://banco-co-onboarding-qa.fif.tech/api/v1/integracion-retail/client',
    service_enroll_integracion_retail: 'https://banco-co-onboarding-qa.fif.tech/api/v1/integracion-retail/enroll',
    service_insurance_offer: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/insurance/v1/offer',

    url_resgistry: 'https://co2cvw255a.falabella.com:8443/registraduria',
    ca_landing: 'https://cuentaahorro-qa.bancofalabella.com.co/personal-data',
    service_activation_update_gmf: 'https://banco-co-onboarding-qa.fif.tech/onboarding/activation/transunion/updateGmf',
    service_validate_user_soy_yo: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/linkage/external/validateUser',
    service_authenticate_user_soy_yo: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/linkage/external/authenticateUser',
    service_get_transaction_soy_yo: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/linkage/external/getTransactions',
    service_create_transaction_soy_yo: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/authentication-ingress/linkage/external/createTransactions',
    service_linkage_update_refused_application: 'https://banco-co-onboarding-qa.fif.tech/onboarding/linkage/client/v1/update-application-reject',
    service_cross_paytrue_notification: 'https://banco-co-onboarding-qa.fif.tech/onboarding/cross/notification-ingress/notificationFaceId'
  };
}
