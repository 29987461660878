<div id="avatar" class="avatar-container" [hidden]="hideAvatar">
  <div class="content-image" (click)="changeState()"></div>
  <div *ngIf="blurredScreenWanted" class="menu-wrapper" (click)="changeState()"></div>
  <div *ngIf="blurredScreenWanted" class="menu" [@changeState]="toogle">
    <div class="user-info">
      <div class="content-image">
        <p class="greeting ml-5">Hola {{ username }}</p>
      </div>
    </div>
    <div class="agent-option">
      <a (click)="goToHome()">
        <img src="{{staticAssetsUrl}}assets/img/icn-home-dark.svg"/>
        <span>Volver al inicio</span>
      </a>
      <a (click)="closeSession()">
        <img src="{{staticAssetsUrl}}assets/img/icn-logout-left-negative.svg"/>
        <span class="pl-1">Cerrar sesión</span>
      </a>
    </div>
  </div>
</div>
