export default [
  {
    "productName": "BLACK",
    "productBenefits": [
      {
        "code": 1,
        "value": "3 ingresos a salas VIP"
      },
      {
        "code": 2,
        "value": "2 años de vigencia de tus CMR Puntos"
      },
      {
        "code": 3,
        "value": "Bono de cumpleaños por $100.000 por compras superiores a $300.000"
      },
      {
        "code": 4,
        "value": "Y todos los beneficios de la CMR"
      }
    ],
    "productCost": [
      {
        "code": "CD",
        "title": "Cupo disponible",
        "value": "0",
        "withColor": true
      },
      {
        "code": "CM",
        "title": "Cuota de manejo",
        "value": "$38.900 al mes"
      },
      {
        "title": "Abonos en línea",
        "value": "Costo $0 a través de PSE"
      },
      {
        "title": "Retiros en cajeros propios",
        "value": "Costo $0"
      },
      {
        "title": "Retiros en cajeros Servibanca",
        "value": "Costo $1.600"
      },
      {
        "code": "FGA",
        "title": "Fianza FGA",
        "value": "$24.500 × 12 meses"
      },
      {
        "code": "FGAT",
        "title": "Total + IVA: $xxxxxx",
        "value": "× 12 meses"
      }
    ],
    "productTitle": "Tarjeta de Crédito Black",
    "productShorttitle": "Tarjeta Black",
    "offer": {
      "costs": [
        {
          "code": "CI",
          "label": "Cupo inicial",
          "value": "$0",
          "withColor": true
        },
        {
          "code": "CM",
          "label": "Costo mensual",
          "value": "<b>$38.900</b> / mes*",
          "withColor": false
        }
      ],
      "title": "¡Tenemos una <strong class='green-text'>CMR Banco Falabella</strong> para ti! <strong class='green-text'>Elige la tuya</strong>",
      "caption": "* Sólo si la usaste o si tienes deuda",
      "benefits": [
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/49ipYEyD5eAfhjFiEfxejO/ebcae483304fad953f1d46ad810ceaf4/ptg-gift-offer.png",
          "text": "<b>Bono de cumpleaños</b>, $100.000 en Falabella por compras mayores a $300.000"
        },
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/66ez5ioYt2qqb1nPOO7QH5/613053547ebacc94bc3283356ccd4a42/ptg-vip-lounge.png",
          "text": "<b>Acceso a salas VIP</b> alrededor del mundo, y ofertas exclusivas en tiendas, restaurantes y spas"
        },
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/7deYGAj76nXSHUp6mYaMO5/6c2eda97fc93a9388c139f5776f4ced1/icon-cmr-puntos.png",
          "text": "Al ser Elite los <b>CMR Puntos</b> que acumules tienen vigencia de 2 años"
        }
      ],
      "fga_costs": [
        {
          "code": "Valor12Meses",
          "label": "Comisión FGA",
          "value": "<b>$15.750</b> × 12 meses",
          "withColor": false
        },
        {
          "code": "ValorComisionIVA",
          "label": "Total + IVA:",
          "value": " $213.000",
          "withColor": false
        },
        {
          "label": "Plazo",
          "value": "12 meses",
          "withColor": false
        }
      ],
      "thumbnail": "https://images.ctfassets.net/ex6ts2p2j0ib/5EHNoj9Jh8XcPXkLVM6o0a/05324528af1d3876cb30e89d54c390db/Black_Card_Thumbnail.png",
      "title_fga": "¡Tenemos una <strong class='green-text'>CMR Banco Falabella</strong> con garantía FGA para ti! <strong class='green-text'>Elige la tuya</strong>",
      "caption_fga": "Sólo pagas cuota de manejo si ocupas tu tarjeta o tienes deuda vigente.",
      "title_resume": "¡Tu <strong class='green-text'>CMR Banco Falabella Black</strong> te está esperando!",
      "fga_costs_detail": [
        {
          "code": "ValorComision",
          "label": "Valor de la comisión",
          "value": "$0",
          "withColor": false
        },
        {
          "code": "IVA",
          "label": "IVA sobre la comisión",
          "value": "$0",
          "withColor": false
        }
      ],
      "title_fga_resume": "¡Tu tarjeta <strong class='green-text'>CMR Banco Falabella Black</strong> con garantía FGA te está esperando!"
    },
    "comparison": {
      "cost": {
        "label": "Cuota de manejo",
        "value": "$38.900 / mes*",
        "withColor": false
      },
      "thumb": "https://images.ctfassets.net/ex6ts2p2j0ib/5EHNoj9Jh8XcPXkLVM6o0a/05324528af1d3876cb30e89d54c390db/Black_Card_Thumbnail.png",
      "title": "CMR Black Banco Falabella",
      "benefits": [
        "3 ingresos a salas VIP",
        "2 años de vigencia de tus CMR Puntos",
        "Bono de cumpleaños por $100.000 por compras superiores a $300.000",
        "Y todos los beneficios de la CMR"
      ],
      "cost_fga": {
        "PZ": {
          "label": "Plazo",
          "value": "12 meses"
        },
        "IVA": {
          "label": "IVA sobre la comisión",
          "value": "$49.400",
          "replaceValue": true
        },
        "Valor12Meses": {
          "label": "Cargo mensual por comisión",
          "value": "$21.667",
          "replaceValue": true
        },
        "ValorComision": {
          "label": "Valor de la comisión",
          "value": "$209.600",
          "replaceValue": true
        },
        "ValorComisionIVA": {
          "label": "Comisión total + IVA",
          "value": "$260.000",
          "replaceValue": true
        }
      },
      "comparator": {
        "cost": "$38.900 / mes",
        "color": "#000000",
        "thumb": "https://images.ctfassets.net/ex6ts2p2j0ib/58PHhYilBHoe3FBAKppCUn/223905ddc6a360702dd4ec189c250167/Artworkblack.svg",
        "title": "CMR Black Elite Banco Falabella",
        "benefits": [
          {
            "primary": "Aceptada en todos los comercios a nivel nacional e internacional",
            "secondary": ""
          },
          {
            "primary": "Todas tus compras acumulan CMR Puntos",
            "secondary": ""
          },
          {
            "primary": "Descuento permanente",
            "secondary": "En Tiendas Falabella, Homecenter, Makro"
          },
          {
            "primary": "Descuento en alianzas",
            "secondary": "Conoce más información en la sección  Promociones - Alianzas"
          },
          {
            "primary": "Tarjetas adicionales",
            "secondary": "Solicita hasta 3 tarjetas adicionales sin costo"
          },
          {
            "primary": "Descuentos en el mes de cumpleaños",
            "secondary": "Beneficios que aplican solo si eres Cliente Elite o Premium. Conoce más <a href='https://www.bancofalabella.com.co/cmr-banco-falabella-black' target='_blank' style='color:#2B8F14'>aquí</a>."
          },
          {
            "primary": "Seguro alquiler de autos*",
            "secondary": ""
          },
          {
            "primary": "Asistencia médica internacional*",
            "secondary": ""
          },
          {
            "primary": "Protección de equipaje en viajes*",
            "secondary": ""
          },
          {
            "primary": "Concierge*",
            "secondary": ""
          },
          {
            "primary": "Envío gratis en compras en falabella.com.co",
            "secondary": "Aplica un mes después de la activación de la Tarjeta de Crédito"
          },
          {
            "primary": "3 ingresos gratis por año en salas VIP del mundo",
            "secondary": "Consulta tasas de interés y tarifas vigentes <a href='https://airport.mastercard.com/' target='_blank' style='color:#2B8F14'>aquí</a>."
          },
          {
            "primary": "Protección de compras*",
            "secondary": ""
          },
          {
            "primary": "Protección contra robo en cajeros automáticos*",
            "secondary": ""
          },
          {
            "primary": "Garantía extendida*",
            "secondary": ""
          }
        ],
        "disclaimer": "* Para acceder a los seguros, será necesario que la totalidad del valor del producto, tiquete aéreo o alquiler de vehículo sea pagado con la Tarjeta de Crédito CMR Banco Falabella Black. Para mayor información, consulta términos y condiciones de las asistencias y seguros <a href='https://www.mastercard.com.co/es-co/consumidores/encontrar-una-tarjeta/tarjetas-de-credito/tarjeta-black.html' target='_blank' style='color:#2B8F14'>aquí</a>."
      }
    }
  },
  {
    "productName": "MASTERCARD",
    "productBenefits": [
      {
        "code": 1,
        "value": "Hasta 40% dcto en tiendas Falabella, Homecenter, Makro y otros establecimientos aliados"
      },
      {
        "code": 2,
        "value": "Hasta 40% dcto todos los días en restaurantes, entretenimiento, viajes, drogerías y más de 40 aliados"
      },
      {
        "code": 3,
        "value": "Acumula CMR puntos en todas tus compras"
      }
    ],
    "productCost": [
      {
        "code": "CD",
        "title": "Tu cupo inicial",
        "value": "0",
        "withColor": true
      },
      {
        "code": "CM",
        "title": "Cuota de manejo*",
        "value": "$26.990 / mes",
        "withColor": false
      },
      {
        "code": "CSO",
        "title": "Seguro de vida deudor**",
        "value": "$2.200 / mes"
      },
      {
        "title": "Pagos en línea",
        "value": "Costo $0 <div>a través de PSE</div>"
      },
      {
        "code": "FGA",
        "title": "Fianza FGA",
        "value": "$24.500 × 12 meses"
      },
      {
        "code": "FGAT",
        "title": "Total + IVA: $xxxxxx",
        "value": "× 12 meses"
      }
    ],
    "productTitle": "Tarjeta de Crédito CMR Banco Falabella",
    "productShorttitle": "Tarjeta de Crédito",
    "offer": {
      "costs": [
        {
          "code": "CI",
          "label": "Cupo inicial",
          "value": "$0",
          "withColor": true
        },
        {
          "code": "CM",
          "label": "Cuota de manejo*",
          "value": "<b>$26.990</b> / mes",
          "withColor": false
        },
        {
          "code": "CSO",
          "label": "Seguro de vida deudor**",
          "value": "<b>$2.200</b> / mes",
          "withColor": false
        }
      ],
      "title": "¡Tenemos una <strong class='green-text' style='display: inline;'>Tarjeta CMR</strong> para ti!",
      "caption": "* Sólo en caso de uso o deuda.",
      "benefits": [
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/5HDAuLjygWYIHLFyaT68KV/bd4df564e623e454665471d4ad904597/icn-cmr-digital.svg",
          "text": "Compra inmediatamente en cualquier comercio online con el número de tarjeta que encontrarás en tu <b>App Banco Falabella</b>"
        },
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/fAZzzkKBiw6zz3gFYK7gT/1515a92f9e6c76c39b9ec7ea0b095de4/oportunidad-unica.svg",
          "text": "<b>Oportunidades Únicas</b> en Tiendas Falabella, Homecenter, Makro y establecimientos aliados"
        },
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/b1fo4itMHUwTsQrNNF1Ul/96ff01c5598cc97fb9c05aa42a10d268/Captura_de_Pantalla_2020-10-14_a_la_s__9.27.37_a.__m..png",
          "text": "Acumula hasta 4 veces más <b>Más CMR Puntos</b> que con otros medios de pago"
        }
      ],
      "fga_costs": [
        {
          "code": "Valor12Meses",
          "label": "Cargo mensual por comisión",
          "value": "<b>$15.750</b> × 12 meses",
          "withColor": false
        },
        {
          "code": "ValorComisionIVA",
          "label": "Comisión Total + IVA:",
          "value": " $213.000",
          "withColor": false
        },
        {
          "label": "Plazo",
          "value": "12 meses",
          "withColor": false
        }
      ],
      "thumbnail": "https://images.ctfassets.net/ex6ts2p2j0ib/6JeqNE5CrbBd7mafoQOets/4d9591aed8eea62d88e6c904f382966e/Card_Thumbnail.png",
      "title_fga": "¡Tenemos una <strong class='green-text'>CMR Banco Falabella Mastercard</strong> con garantía FGA para ti!",
      "caption_fga": "* Solo en caso de uso o deuda.",
      "title_resume": "Tu <strong class='green-text'>Tarjeta CMR</strong> Banco Falabella te está esperando",
      "captionInsurance": "** Sólo en caso de deuda.",
      "fga_costs_detail": [
        {
          "code": "ValorComision",
          "label": "Valor de la comisión",
          "value": "$0",
          "withColor": false
        },
        {
          "code": "IVA",
          "label": "IVA sobre la comisión",
          "value": "$0",
          "withColor": false
        }
      ],
      "title_fga_resume": "¡Tu <strong style='display:inline' class='green-text'>Tarjeta CMR Banco Falabella Mastercard</strong> con garantía FGA te está esperando!"
    },
    "comparison": {
      "cost": {
        "label": "Cuota de manejo",
        "value": "$26.990 / mes*",
        "withColor": false
      },
      "thumb": "https://images.ctfassets.net/ex6ts2p2j0ib/6JeqNE5CrbBd7mafoQOets/4d9591aed8eea62d88e6c904f382966e/Card_Thumbnail.png",
      "title": "CMR Banco Falabella",
      "benefits": [
        "Aprovecha ahorros permanentes de hasta un 40% en Tiendas Homecenter, Falabella y establecimientos aliados.",
        "Todas tus compras acumulan CMR Puntos."
      ],
      "cost_fga": {
        "PZ": {
          "label": "Plazo",
          "value": "12 meses"
        },
        "IVA": {
          "label": "IVA sobre la comisión",
          "value": "$49.400",
          "replaceValue": true
        },
        "Valor12Meses": {
          "label": "Cargo mensual por comisión",
          "value": "$21.667",
          "replaceValue": true
        },
        "ValorComision": {
          "label": "Valor de la comisión",
          "value": "$209.600",
          "replaceValue": true
        },
        "ValorComisionIVA": {
          "label": "Comisión total + IVA",
          "value": "$260.000",
          "replaceValue": true
        }
      },
      "comparator": {
        "cost": "$26.990 / mes",
        "color": "#43B02A",
        "thumb": "https://images.ctfassets.net/ex6ts2p2j0ib/1ZrKwUahK6d53UzwLO9Jea/da92be3995a6d4c7028b4b6701f8061d/bfcl-cmr-clasica-mastercard-comms-ptcMasterCard.svg",
        "title": "CMR Banco Falabella",
        "benefits": [
          {
            "primary": "Aceptada en todos los comercios a nivel nacional e internacional",
            "secondary": ""
          },
          {
            "primary": "Todas tus compras acumulan CMR Puntos",
            "secondary": ""
          },
          {
            "primary": "Descuento permanente",
            "secondary": "En Tiendas Falabella, Homecenter y Makro"
          },
          {
            "primary": "Descuento en alianzas",
            "secondary": "Conoce más información en la sección  Promociones - Alianzas"
          },
          {
            "primary": "Tarjetas adicionales",
            "secondary": "Solicita hasta 3 tarjetas adicionales sin costo"
          }
        ]
      }
    }
  },
  {
    "productName": "CA",
    "productBenefits": [
      {
        "code": 7,
        "value": "Todos los días hasta 40% de descuento en más de 40 aliados"
      },
      {
        "code": 5,
        "value": "Acumula CMR puntos en todas tus compras."
      },
      {
        "code": 9,
        "value": "Costo $0 en cuota de manejo, transferencias y retiros."
      }
    ],
    "productCost": [
      {
        "code": "CM",
        "title": "Cuota de manejo",
        "value": "Costo $0"
      },
      {
        "title": "Abonos en línea",
        "value": "Costo $0 <div>a través de PSE</div>"
      },
      {
        "title": "Retiros en cajeros <div>propios</div>",
        "value": "Costo $0"
      },
      {
        "title": "Retiros en cajeros <div>Servibanca</div>",
        "value": "$0"
      }
    ],
    "productTitle": "Cuenta de Ahorro Costo $0",
    "productShorttitle": "Cuenta de Ahorro",
    "productDisclaimer": "<strong>Condiciones:</strong> Para disfrutar de estos beneficios debes tener ingresos mensuales en esta cuenta iguales o superiores a $750.000.",
    "offer": {
      "costs": [
        {
          "label": "Cuota de manejo",
          "value": "Costo $0",
          "withColor": true
        }
      ],
      "title": "¡Tenemos una <strong class='green-text'>Cuenta de Ahorros</strong> para ti!",
      "benefits": [
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/27Nf4NqxQ6vSoYRdfsMbpb/500e9bb5dce46e80de2602b82e4bb600/icn-costo-0-s.svg",
          "text": "<b>Tienes retiros gratis e ilimitados </b>en cajeros Servibanca y hasta 3 retiros gratis al mes en cajeros de cualquier banco."
        },
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/fAZzzkKBiw6zz3gFYK7gT/1515a92f9e6c76c39b9ec7ea0b095de4/oportunidad-unica.svg",
          "text": "<b>Descuentos todos los días</b> en Homecenter, Falabella, Falabella.com y en más de 40 comercios aliados."
        },
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/b1fo4itMHUwTsQrNNF1Ul/96ff01c5598cc97fb9c05aa42a10d268/Captura_de_Pantalla_2020-10-14_a_la_s__9.27.37_a.__m..png",
          "text": "<b>Acumulas CMR Puntos </b>en todas tus compras con tu Tarjeta Débito."
        }
      ],
      "insurance": {
        "text": "Producto protegido con el seguro de depósitos Fogafín",
        "thumbnail": "https://images.ctfassets.net/ex6ts2p2j0ib/6wtn3yDqsItwrryfOppix0/331d0c14c4859ed0c28c4948748b7796/badge-fogafin.png",
        "explanatory": ""
      },
      "thumbnail": "https://images.ctfassets.net/ex6ts2p2j0ib/5V6JcKLhUSxMDq4LEkRJZh/bf1dbcab070139135295bd4690854424/Card_-_CA_Thumbnail.png",
      "title_resume": "Tu <strong class='green-text'>Cuenta de Ahorros</strong> te está esperando"
    }
  },
  {
    "productName": "TC",
    "productBenefits": [
      {
        "code": 1,
        "value": "<strong>Acumula CMR Puntos</strong> en todas tus compras para canjear recompensas"
      },
      {
        "code": 2,
        "value": "Disfruta de <strong>descuentos exclusivos</strong> en Falabella y Homecenter"
      },
      {
        "code": 3,
        "value": "<strong>Compra en todo el mundo</strong> y paga en pesos con tu CMR Mastercard"
      }
    ],
    "productCost": [],
    "productTitle": "Tarjeta de Crédito CMR",
    "productShorttitle": "Tarjeta de Crédito CMR",
    "offer": {
      "costs": [
        {
          "label": "Cupo inicial",
          "value": "$1.800.000",
          "withColor": true
        },
        {
          "label": "Costo mensual",
          "value": "$21.990 / mes*",
          "withColor": false
        }
      ],
      "title": "¡Tenemos una <strong class='green-text'>Tarjeta CMR</strong> para ti!",
      "caption": "* Sólo si la usaste o si tienes deuda",
      "benefits": [
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/5HDAuLjygWYIHLFyaT68KV/bd4df564e623e454665471d4ad904597/icn-cmr-digital.svg",
          "text": "Compra inmediatamente en cualquier comercio online con el número de tarjeta que encontrarás en tu <b>App Banco Falabella</b>"
        },
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/fAZzzkKBiw6zz3gFYK7gT/1515a92f9e6c76c39b9ec7ea0b095de4/oportunidad-unica.svg",
          "text": "<b>Oportunidades Únicas</b> en Tiendas Falabella, Homecenter, Makro y establecimientos aliados"
        },
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/b1fo4itMHUwTsQrNNF1Ul/96ff01c5598cc97fb9c05aa42a10d268/Captura_de_Pantalla_2020-10-14_a_la_s__9.27.37_a.__m..png",
          "text": "Acumula hasta 4 veces más <b>Más CMR Puntos</b> que con otros medios de pago"
        }
      ],
      "thumbnail": "https://images.ctfassets.net/ex6ts2p2j0ib/6dh9LleRigGQbraYWVZ0UB/910388fd54f9c16ac279d9fcea06ab56/Card_Thumbnail_Mastercard.svg",
      "title_resume": "Tu <strong class='green-text'>Tarjeta CMR</strong> Banco Falabella te está esperando"
    }
  },
  {
    "productName": "PAC",
    "productBenefits": [
      {
        "code": 4,
        "value": "¡Haz ese sueño realidad! Ahorra con alta rentabilidad, sin plazo de permanencia"
      },
      {
        "code": 5,
        "value": "Costo $0 en todo, nunca pagarás cuota de manejo"
      },
      {
        "code": 6,
        "value": "Tienes tu dinero 100% disponible cuando lo necesites"
      }
    ],
    "productCost": [
      {
        "code": "CM",
        "title": "Cuota de manejo",
        "value": "Costo $0"
      },
      {
        "title": "Retiro del dinero",
        "value": "100% disponible <div>cuando lo necesites</div>"
      },
      {
        "code": "TI",
        "title": "Tasas de interés",
        "value": "hasta 8,50% E.A"
      },
      {
        "title": "Abonos en línea",
        "value": "Costo $0 <div>a través de PSE</div>"
      }
    ],
    "productTitle": "Cuenta de Ahorros PAC",
    "productShorttitle": "Cuenta de Ahorros PAC",
    "offer": {
      "costs": [
        {
          "label": "Cuota de manejo",
          "value": "Costo $0",
          "withColor": true
        }
      ],
      "title": "¡Tenemos una <strong class='green-text'>Cuenta de Ahorros PAC</strong> para ti!",
      "benefits": [
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/4xSb4tQuLsAuCTOqnkXQiQ/ef643b821b76872d446bdeca2b4be40c/icn-money.svg",
          "text": "<b>Retiros ilimitados,</b> tu dinero 100% disponible cuando lo necesites"
        },
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/53NHlLqCuPOy0zQch1N4XS/6b30f33594f3fa4a12c582fedc4113e7/icn-interest-rate.svg",
          "text": "<b>Tasas de interés preferencial</b> de hasta el 2,85% E.A. sin plazo de permanencia"
        },
        {
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/27Nf4NqxQ6vSoYRdfsMbpb/500e9bb5dce46e80de2602b82e4bb600/icn-costo-0-s.svg",
          "text": "<b>Abonos Costo $0 en línea,</b> desde otros bancos o cualquier punto VIA Baloto"
        }
      ],
      "insurance": {
        "text": "Los depósitos en Cuentas de Ahorros de Banco Falabella S.A. están cubiertos con el seguro de depósitos FOGAFÍN.",
        "thumbnail": "https://images.ctfassets.net/ex6ts2p2j0ib/6wtn3yDqsItwrryfOppix0/331d0c14c4859ed0c28c4948748b7796/badge-fogafin.png",
        "explanatory": ""
      },
      "thumbnail": "https://images.ctfassets.net/ex6ts2p2j0ib/4zrkmv7VOJJn8rZKBsnIfg/923a93d3688ad2ec94d494f7d17373f0/saving.png",
      "title_resume": "Tu <strong class='green-text'>Cuenta de Ahorros PAC</strong> te está esperando"
    }
  },
  {
    "productName": "CERRADA",
    "productBenefits": [
      {
        "code": 1,
        "value": "<strong>Acumula CMR Puntos</strong> en todas tus compras para canjear recompensas"
      },
      {
        "code": 2,
        "value": "Disfruta de <strong>descuentos exclusivos</strong> en Falabella y Homecenter"
      },
      {
        "code": 3,
        "value": "<strong>Compra en todo el mundo</strong> y paga en pesos con tu CMR Mastercard"
      }
    ],
    "productCost": [
      {
        "code": "CD",
        "title": "Cupo disponible",
        "value": "0"
      },
      {
        "code": "CM",
        "title": "Cuota de manejo",
        "value": "$18.990 al mes"
      },
      {
        "title": "Abonos en línea",
        "value": "Costo $0 a través de PSE"
      },
      {
        "title": "Retiros en cajeros propios",
        "value": "Costo $0"
      },
      {
        "title": "Retiros en cajeros Servibanca",
        "value": "Costo $1.600"
      }
    ],
    "productTitle": "Tarjeta de Crédito CMR",
    "productShorttitle": "Tarjeta de Crédito",
    "offer": {
      "title": "¡Tenemos una <strong class='green-text'>Tarjeta CMR</strong> para ti!",
      "title_resume": "Tu <strong class='green-text'>Tarjeta CMR</strong> Banco Falabella te está esperando",
      "thumbnail": "https://images.ctfassets.net/ex6ts2p2j0ib/6wWs3zIZrpBW9z3tkQs3a7/e282349ea4a01ed5e0c0df0f72d83b7d/CC.svg",
      "costs": [
        {
          "value": "$1.800.000",
          "label": "Cupo inicial",
          "withColor": true
        },
        {
          "value": "$18.990 / mes*",
          "label": "Costo mensual",
          "withColor": false
        }
      ],
      "benefits": [
        {
          "text": "Compra inmediatamente en cualquier comercio online con el número de tarjeta que encontrarás en tu <b>App Banco Falabella</b>",
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/5HDAuLjygWYIHLFyaT68KV/bd4df564e623e454665471d4ad904597/icn-cmr-digital.svg"
        },
        {
          "text": "<b>Oportunidades Únicas</b> en Tiendas Falabella, Homecenter, Makro y establecimientos aliados",
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/fAZzzkKBiw6zz3gFYK7gT/1515a92f9e6c76c39b9ec7ea0b095de4/oportunidad-unica.svg"
        },
        {
          "text": "Acumula hasta 4 veces más <b>Más CMR Puntos</b> que con otros medios de pago",
          "icon": "https://images.ctfassets.net/ex6ts2p2j0ib/b1fo4itMHUwTsQrNNF1Ul/96ff01c5598cc97fb9c05aa42a10d268/Captura_de_Pantalla_2020-10-14_a_la_s__9.27.37_a.__m..png"
        }
      ],
      "caption": "* Sólo si la usaste o si tienes deuda"
    }
  }
];