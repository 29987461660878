import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralConfigService {

  constructor() { }

  public getApiUrl(apiName: string): string {
    return environment.api[apiName];
  }
}
