import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GlobalErrorHandlerService, LoggerService } from 'cob-infrastructure';
import { Analytics } from 'fif-common-analytics';
import { ContentfulService } from './services/contentful/contentful.service';
import { GlobalAuthGuard } from './services/global-guard/auth-guard';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  providers: [],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        GlobalAuthGuard,
        LoggerService,
        GlobalErrorHandlerService,
        ContentfulService,
        Analytics.TaggingAdapter
      ],
    };
  }
}
