import { Api } from './api/api.qa';
import { App } from './app/app.local';
import { Common } from './common/common.local';

export const environment: any = {
  name: 'qa',
  country: 'ONBCO_WEB',
  useAuthenticate: true,
  production: true,
  api: Api.apis,
  common: Common.commons,
  token: {
    loginUrl: Api.apis.service_login,
    revokeUrl: Api.apis.service_revoke_token,
    refreshUrl: Api.apis.service_refresh_token,
    graceTime: 30,
  },
  app: App.confs,
  cipher: {
    useInRequest: true,
    useInResponse: true,
    disableInStorage: false,
    storageKey: 'f8220093c8a07faa9d6eec88827cf0c9',
    publicKey:
    '-----BEGIN PUBLIC KEY-----\n' +
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr/uJqitbcJG/9jLH7lDu\n' +
    'X/jg5Q2VxDh4iwBtQvPigZiZRuChuowU9XmW+XS6J8N1lXcpe9vhErBFKX/9ELAa\n' +
    'r3PnIYVnjtVqLxro2f0eqocdWw1AN2lx01SqxiqmdHEZCsj9mEQ6sYNcNdeH6G/9\n' +
    'GNk/3Y7c0H90Gi/+vWYN1sVr+LJR5CU/emdO1W2FaQ8T2+GWhyNVOkDZEx4dTsDE\n' +
    'cX0PmGUwxPv+2gZb99fNxhwKV4Vq5Mt9ACAl3vrhUH33kZPuw3miJHKXvlw+9kuU\n' +
    'uOFJFOga/D9FMfCrYcCqlBuzf2T6w0Q19Ik+uyo3zLn3YFN1ufBfeAV51Z0kKBiT\n' +
    'zwIDAQAB\n' +
    '-----END PUBLIC KEY-----',
  },
  flagr: {
    url: 'https://flagr-qa-priv.fif.tech/api',
    singleEvaluation: '/flag/evaluation/v1/single',
    batchEvaluation: '/flag/evaluation/v1/batch',
    pubKey: '-----BEGIN PUBLIC KEY-----' +
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApkALLhocuyoPO/MgvnsF' +
    'mBcX+z0HYoSQnLKS9E+J3LaVOlLhgQRBs5Ygr/1mFXArtKuIMimDG8GCi9r8YdYn' +
    't57FzBZD4ddICmFVsbQJmmWrJVoF7t6DFMzK4EKP5KXJ3b2PDdKP7WTi4ecJDry5' +
    'GTHE/CCk/AxeRDdSYCYwDCoMmzgCcpqouQxcgJ2LUWQdENjDq+jzHzOdm2v56chO' +
    '4HpeBBIYLYr+ZwBfHQj8LgpdxDDxw8nsfRBgaebTK2jQrAiiYaqjy0tHrrpxxtQL' +
    'bagRDji2kIfeuAleLHaX2fZXw3PlI8KiWRd36XDn4kkfnz5XL8Zvk71nV3XIiEIv' +
    'XwIDAQAB' +
    '-----END PUBLIC KEY-----',
    flags: {
      multiproduct: {
        flagID: 100,
        flagKey: 'rout_bfco_onbording_multiproducto_autoasistido',
      },
      faceId: {
        flagID: 201,
        flagKey: 'rout_bfco_onbording_face_id_flow',
      },
      obligatory_insurance: {
        flagID: 246,
        flagKey: 'rout_bfco_onboarding_obligatory_insurance',
      }
    }
  },
  CAPTCHA_ENABLE: false,
  CAPTCHA_KEY: '6LfiADUaAAAAAFgH7tALwyTG5qG96W-VsOn18-oy', // 6Lf7d30UAAAAAG9N3_szs04yAK0vOcE9_eai-K3_
  staticAssetsUrl: '',
  keysToCleanLocalStorage: ['is-ca-flow','content','personal-data','isCallcenterUser','isInPersonChannel',
    'person','sessionId','expires_in','expires_in_date','access_token','refresh_token','username',
    'loginType', 'signature-retries','extraField','additional-data','employment-info','intentTypeAuthentication',
    'isClientByType','registry-success','second','requested-products','is-tc','is-pac','is-ca',
    'insurance-selection','moreInfo','is-fraud','account-number','password-created','_hjid','randomseed',
    'flowSessionId','page-loader-evaluate','page-loader-evaluate-full','qa'],
  datadog: {
    applicationId: '6a603b46-965f-4bc8-8d91-29c683fd6c85',
    clientToken: 'pub0eca3327b86a0919f06d813a2b597ee9',
    site: 'datadoghq.com',
    service: 'banco-co-onboarding-tarjeta-preprod',
    env: 'qa',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  },
  dataDogConfigName: 'configTarjetaQa',
  faceId: {
    INIT: {
      API_KEY: 'PBxc0p3bsb4E2gqSzx29oDwaBgQPFM377ASwIBic',
      CLIENT_ID: '4v035e06dvnuvu4lkpnu5ftcjh',
      CLIENT_SECRET: 'esgb1qamps2addbg82kbh8qskrm32vauvfpkeuae4cnup9r29hk',
      PRODUCTION: false, // Para el ambiente de SNB configurar en false, para producción en true
      ENTITY_ID: '40', // Identificador de la entidad para activar la personalización, si no desea activar la personalización no configurarlo.,
    },
    PROCESS_TYPE: "ENR",
    CHANNEL:"WEB_CLIENT",
    ACCEPT_TYC_EXTERNAL: false,
    OTP_IN_TERNAL: true,
    DOCUMENT_TYPE: "CC",
    PHONE_INDICATIVE: "57",
    APP_IDENTIFIER: "solicitudes.bancofalabella.com.co",
    PROCEDURE_ID: 129
  }
};
