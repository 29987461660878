import { Injectable } from '@angular/core';
import { GeneralConfigService } from '@shared/services/general-config-service';
import { SessionKeys } from '@shared/domain/session-keys';
import { InvokerService, SessionStorageHandler } from 'cob-infrastructure';
import { Analytics } from 'fif-common-analytics';

@Injectable()
export class RetailDataHandler {
  constructor(
    private sessionStorageHandler: SessionStorageHandler,
    private invokerService: InvokerService,
    private generalConfigService: GeneralConfigService,
    private taggingAdapter: Analytics.TaggingAdapter,
  ) { }


  public async setRetailId(): Promise<any> {
    const commerce = sessionStorage.getItem('commerce');
    const retailId = sessionStorage.getItem('retailId');
    const body = { retailId };
    await this.invokerService.post(
      body,
      this.generalConfigService.getApiUrl('service_get_customer_integracion_retail'),
      { module: 'retail-data-handler', class: 'PersonalDataHandler', method: 'setRetailId' },
      { headers: { 'x-session-id': 'null', 'x-commerce': commerce }}, false).then((result) => {
        this.sessionStorageHandler.setValue('retailData', JSON.stringify(result.message));
        this.taggingAdapter.tagEvent('get-customer-retail', 'ok - get customer', 'OK');
        return result.message;
      }).catch((err) => {
        this.taggingAdapter.tagEvent('get-customer-retail', 'error - get customer', err);
        return null;
      });
  }

  /**
   *
   * @param info objeto con informacion adicional, caso error ejem: {errorInfo: {code: 'ERROR_CODE'}}
   * @param panObfuscated string con pan ofuscada
   */
  public async notifyApiRetail(info = null, panObfuscated = null): Promise<any> {
    try {
      const hasBeenCalled = !!this.sessionStorageHandler.getValue('enrollHasBeenCalled');
      if (hasBeenCalled) {
        return
      }
      this.sessionStorageHandler.setValue('enrollHasBeenCalled', 'true');

      let hashId = '';
      const retailId = sessionStorage.getItem('retailId');
      const retailData = JSON.parse(this.sessionStorageHandler.getValue('retailData'));
      const { commerce, hasDiscount } = retailData;
      const { documentType, documentId } = JSON.parse(this.sessionStorageHandler.getValue('personal-data'));
      const hasPAN = panObfuscated ? true : false;
      let body = {};

      body = {
        numberId: documentId,
        typeId: documentType,
        retailId,
        hasDiscount,
        pan: panObfuscated,
        hashId
      };
      if (info) {
        if(info.errorInfo && !info.errorInfo.code) {
          info.errorInfo.code = 'CODE_NULL'
        }
        body = { ...body, info: JSON.stringify(info) };
      }

      if (window['dataLayer']) {
        const userData = window['dataLayer'].find(event => event.eventCategory === 'Logged In');
        if (userData) {
          body = { ...body, hashId: userData.userID };
        }
      }
      Object.keys(body).forEach((key) => (body[key] == null) && delete body[key]);
      this.invokerService.post(
        body,
        this.generalConfigService.getApiUrl('service_enroll_integracion_retail'),
        { class: 'RetailDataHandler', method: 'notifyApiRetail' },
        { headers: { 'x-commerce': commerce } },
        false
      ).then(() => {
        this.taggingAdapter.tagEvent('retail flow',
          `notifyApiRetail - enroll - hasDiscount: ${hasDiscount} - hasPAN ${hasPAN}`, 'ok');
      }).catch((err) => {
        this.taggingAdapter.tagEvent('retail flow',
          `notifyApiRetail - enroll - hasDiscount: ${hasDiscount} - hasPAN ${hasPAN}`, 'error');
      })
    } catch (err) {
      this.taggingAdapter.tagEvent('retail-data.handler', 'error - notifyApiRetail', err);
    }

  }

}
