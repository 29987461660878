<div id="header" class="header">
  <div onclick="diplayMenu()" class="hamburguesa" id="menu-hamburguesa">
    <img *ngIf="isAnonymousUser()" src="{{staticAssetsUrl}}assets/img/header/ico-nav.svg" alt="navegación" />
  </div>
  <img src="{{staticAssetsUrl}}assets/img/logo-banco-mobile.svg" onerror="this.src='assets/img/logo_banco.png'"
    class="logo-mobile only-mobile" alt="logo mobile" />
  <img src="{{staticAssetsUrl}}assets/img/logo-banco-falabella.svg" onerror="this.src='assets/img/logo-banco-falabella.png'"
    class="logo-desktop only-desktop" alt="logo desktop" />

  <ul *ngIf="isAnonymousUser()" class="main-menu" id="navNotLogedUser">
    <li class="menu-item">
      <a href="https://www.bancofalabella.com.co/cuenta-de-ahorros" class="">Cuenta Ahorros</a>
    </li>
    <li class="menu-item">
      <a href="https://www.bancofalabella.com.co/cuenta-ahorro-pac" class="">Cuenta-PAC</a>
    </li>
    <li class="menu-item">
      <a href="https://www.bancofalabella.com.co/cmr-banco-falabella-mastercard" class="">Tarjetas</a>
    </li>
    <li class="menu-item">
      <a href="https://www.bancofalabella.com.co/cdt" class="">CDT</a>
    </li>
    <li class="menu-item">
      <a href=" https://www.bancofalabella.com.co/credito-consumo" class="">Créditos</a>
    </li>
  </ul>

  <div id="mask" class="mask" onclick="diplayMenu()"></div>
</div>