import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonFunctions } from '@shared/functions/common.functions';
import { SessionStorageHandler } from 'cob-infrastructure';
import { ContentfulService } from '@core/services/contentful/contentful.service';
import { SessionKeys } from '@shared/domain/session-keys';
import { environment } from '@environment/environment';
import { FlagrService } from '@core/services/data/flagr.service';
import { FlagrBodyContent } from 'src/app/interfaces/flagr.interfaces';
import { Analytics } from 'fif-common-analytics';

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductOverviewComponent implements OnInit {
  @Input() products;
  @Input() obligatoryInsurance;
  staticAssetsUrl = environment.staticAssetsUrl;
  constructor(
    private sessionStorageHandler: SessionStorageHandler,
    private contentFullService: ContentfulService
  ) {}

  async ngOnInit() {
    this.products = this.products || [...this.getRequestedProducts(),...this.getInsurances()];
  }

  getRequestedProducts = () => {
    const isFga = this.sessionStorageHandler.getValue(SessionKeys.IS_FGA) === 'true';
    const requestedProducts = JSON.parse(this.sessionStorageHandler.getValue(SessionKeys.REQUESTED_PRODUCTS) || '[]');
    const contentfulProducts = this.contentFullService.getProductsContent();
    const products = contentfulProducts.filter((product) => (requestedProducts.includes(product.productName)));
    const costs = ['CM','CD','TI','FGA'];
    const obligatoryInsurance = this.obligatoryInsurance;
    const captionOptions = ['caption'];
    if(obligatoryInsurance) {
      costs.push('CSO');
      captionOptions.push('captionInsurance');
    }

    const tcNames = ['CERRADA', 'MASTERCARD', 'BLACK','TC'];
    return products.map((product) => {
      const { productName, offer: { thumbnail } } = product;
      const name = product.productTitle;
      let attrs = product.productCost.filter((cost) => (costs.includes(cost.code)));
      if(tcNames.includes(product.productName)) {
        const second = JSON.parse(this.sessionStorageHandler.getValue(SessionKeys.SECOND_EVALUATE));
        const amountGranted = second.detail[product.productName].montoOtorgado;
        attrs.find((attr) => (attr.code === 'CD')).value = CommonFunctions.formatNumber(amountGranted);
        if(isFga) {
          captionOptions.push('caption_fga');
          captionOptions.shift();
          const isResume = this.sessionStorageHandler.getValue(SessionKeys.IS_RESUME) === 'true';
          const fgaValue = isResume ? second.extraField.valorMes : second.extraField.find((field) => field.nombreAtributo === 'Valor12Meses').valorAtributo;
          attrs.find((attr) => (attr.code === 'FGA')).value = CommonFunctions.formatNumber(fgaValue) + ' x 12 meses';
        } else {
          attrs = attrs.filter((attr) => (attr.code !== 'FGA'));
        }
      }

      const leakedCaptions = captionOptions.filter((attr) => (Object.keys(product.offer).includes(attr)));
      const captions = leakedCaptions.map(attr => (product.offer[attr]));

      return { name, attrs, thumbnail, captions, productName };
    });
  }

  getInsurances = () => {
    const requestedProducts = JSON.parse(this.sessionStorageHandler.getValue(SessionKeys.REQUESTED_PRODUCTS) || '[]');
    if (!CommonFunctions.isTc(requestedProducts)) return [];
    const thumbnailOptions = ['fraude', 'desempleo'];
    const insurancesSelection = JSON.parse(this.sessionStorageHandler.getValue(SessionKeys.INSURANCE_SELECTION) || '[]');
    const captions = [];
    return insurancesSelection.map((insurance) => { 
      const name = insurance.name;
      const thumbnailFound = thumbnailOptions.find(thumbnail => (insurance.name.toLowerCase().includes(thumbnail)));
      const thumbnail = thumbnailFound ? `${this.staticAssetsUrl}assets/img/${thumbnailFound}.png` : undefined;
      const attrs = [{title: 'Valor Mensual', value: insurance.valueCOP + ' / mes'}];
      return { name, attrs, thumbnail, captions };
    });
  }
}
