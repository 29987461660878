import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '../src/environments/environment';
import * as dev from './environments/environment.dev';
import * as prod from './environments/environment.prod';
import * as qa from './environments/environment.qa';
import { Analytics } from 'fif-common-analytics';
import 'fif-common-analytics/dinamicLoader';
import { CONFIG_APP, CONFIG_CIPHER, CONFIG_COMMON, CONFIG_TOKEN, CONFIG_LOGGER } from 'cob-infrastructure';


const enviroments = { dev, qa, prod };

(async () => {
  const env = (window as any).bootstrapSettings.env;
  console.log("env", env)
  console.log("main environment.name", environment.name)
  console.log("main environment.country",  environment.country)
  // Si no se hizo el reeplace a través del stack en el index.html, se usa el enviroment ya reemplazado en el angular.json.
  if (env !== 'ENV-XXX') Object.assign(environment, enviroments[env].environment);

  if (environment.production) enableProdMode();

  const apiLogConfig = environment.api.service_front_log
  const loggerConfig = (typeof apiLogConfig === 'string') ? { endpoint: apiLogConfig } : apiLogConfig;

  console.log("main environment.name", environment.name)
  console.log("main environment.country",  environment.country)

  const ProvidersReady = Analytics.TaggingAdapter.dynamicallyLoadProvider(environment.name, environment.country);


  platformBrowserDynamic([
    { provide: CONFIG_TOKEN, useValue: environment.token },
    { provide: CONFIG_APP, useValue: environment.app },
    { provide: CONFIG_CIPHER, useValue: environment.cipher },
    { provide: CONFIG_COMMON, useValue: environment.common },
    { provide: CONFIG_LOGGER, useValue: loggerConfig },
  ]).bootstrapModule(AppModule)
    .catch(err => console.error(err));
})();
