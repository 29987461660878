import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '@environment/environment';

@Injectable()
export class DatadogService {
  config: any = environment.datadog;

  loadScript = async (config = null): Promise<any> => {
      const newConfig = { ...this.config, ...config };
      const configAsString = JSON.stringify(newConfig);
  
      const scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.text = `
        (function(h,o,u,n,d) {
          h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
          d=o.createElement(u);d.async=1;d.src=n
          n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
          })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
          window.DD_RUM.onReady(function() {
            window.DD_RUM.init(${configAsString});
        })`;
  
      document.body.appendChild(scriptTag);
      return Promise.resolve();
  }

  addContextUser = (userData: any): void => {
    const ddRum = (window as any).DD_RUM;
    const isEmpty = (input: any) => input && Object.getOwnPropertyNames(input).length === 0;

    if (ddRum !== undefined) {
      ddRum.onReady(function () {
        ddRum.addRumGlobalContext('onboarding', { ...userData });
        if(isEmpty(ddRum.getUser())) {
          ddRum.setUser({ ...userData });
        }
      });
    }
  }

  addCustomEvent = (eventCustom: string, extraMetaData?: any): void => {
    const metaData = {
      value: eventCustom,
      ...extraMetaData
    };
      if ((window as any).DD_RUM !== undefined) {
        (window as any).DD_RUM.onReady(function () {
          (window as any).DD_RUM.addAction(eventCustom, metaData);
        });
      }
  }

  addCustomError = (eventName: string, error: Error, extraMetaData?: any): void => {
    const metaData = {
      value: eventName,
      ...extraMetaData
    };
    if ((window as any).DD_RUM !== undefined) {
      (window as any).DD_RUM.onReady(function () {
        (window as any).DD_RUM.addError(error, metaData);
      });
    }
  }
}
