export enum RoutesPaths {
  ADDITIONAL_DATA = 'additional-data',
  FIRTS_EVALUATE = 'evaluate/checkingEvaluate',
  PRODUCT_CROSSING = 'product-crossing',
  PRODUCT_CROSSING_WITHOUT_OFFER = 'product-crossing-without-offer',
  EMPLOYMENT_INFO = 'employment-info',
  GENERIC_ERROR = 'evaluate/error',
  PERSONAL_DATA = 'personal-data',
  LANDING = '',
  FAILED_PROCESS = 'failed-process',
  OFFER = 'evaluate/withOffer',
  SALES_AGENT_LOGIN = 'login',
  UNAUTHORIZED = 'unauthorized',
  UNAUTHORIZED_ERROR = 'unauthorized-error',
  WITHOUT_OFFER = 'evaluate/withoutOffer',
  AUTHENTICATION = 'evaluate/authentication',
  RETURN_OFFER = 'return-offer',
  PRODUCTS_SELECTION = 'products-selection',
  SECOND_EVALUATE = 'evaluate/checkingEvaluateFull',
  BIRTHPLACE_LOCAL = 'birthplace-local',
  BIRTHPLACE_FOREIGNER = 'birthplace-foreigner',
  JOB_INFO = 'job-info',
  PEP_FATCA = 'pep-fatca',
  PAYMENT_DATE = 'payment-date',
  PAC_PURPOSE = 'pac-purpose',
  PRODUCT_READY = 'product-ready',
  CLIENT_CREATE = 'client-create',
  UPLOAD_ID = 'upload-id',
  SIGNATURE = 'signature',
  PRINT_DOCUMENTS = 'print-documents',
  CREATE_WEB_PASSWORD = 'create-web-password',
  FINISHED_PROCESS = 'finished-process',
  WEB_PASSWORD_STATE = 'web-password-state',
  // USE_YOUR_PRODUCT = 'use-your-product'
  USE_YOUR_PRODUCT = 'process-finished', // Se dejo como la ruta anterior para no afectar analytics
  RESUME_APPLICATION = 'resume-application',
  GO_TO_BRANCH = 'go-to-branch',
  RESGISTRY_ERROR = 'registry-error',
  CA_PRODUCT_FINISHED = 'savings-account-product-finished',
  SIGNATURE_REGISTRATION = 'signature-registration',
  CONTINGENCY_SIGNATURE = 'contingency-signature',
  INSURANCE_SELECTION = 'insurance-selection',
  NO_TC_OFFER = 'no-tc-offer',
  CREATE_PRODUCT = 'create-product',
  UPDATE_GMF = 'update-gmf',
  PRODUCT_RESUME = 'product-resume',
  SIGNATURE_RESUMEN = 'signature-resume',
  FACE_ID = 'face-id',
  PERSONAL_ADDRESS = 'personal-address'
}
