export default [
  {
    "nombreSeccion": "Sección superior",
    "titulo": "Solicita tu <strong  class='green-text'>Tarjeta de Crédito CMR Banco Falabella</strong> en pocos minutos",
    "background": [
      {
        "metadata": {
          "tags": []
        },
        "sys": {
          "space": {
            "sys": {
              "type": "Link",
              "linkType": "Space",
              "id": "ex6ts2p2j0ib"
            }
          },
          "type": "Asset",
          "id": "5ScJpKu3mNrf6NFYYV9YI6",
          "revision": 7,
          "createdAt": "2021-01-15T18:14:17.570Z",
          "updatedAt": "2023-04-26T15:22:58.215Z",
          "environment": {
            "sys": {
              "id": "master",
              "type": "Link",
              "linkType": "Environment"
            }
          },
          "locale": "es"
        },
        "fields": {
          "title": "BG-TDC-OCO",
          "description": "Imagen de fondo en formato webp",
          "file": {
            "url": "//images.ctfassets.net/ex6ts2p2j0ib/5ScJpKu3mNrf6NFYYV9YI6/d7b586cef5ca69acc3f5682d76f703be/BG-Formulario-CMR--2023--3.png",
            "details": {
              "size": 927993,
              "image": {
                "width": 1441,
                "height": 641
              }
            },
            "fileName": "BG-Formulario-CMR--2023--3.png",
            "contentType": "image/png"
          }
        }
      },
      {
        "metadata": {
          "tags": []
        },
        "sys": {
          "space": {
            "sys": {
              "type": "Link",
              "linkType": "Space",
              "id": "ex6ts2p2j0ib"
            }
          },
          "type": "Asset",
          "id": "YVtejJOf50JZ8LNENHj1W",
          "revision": 5,
          "createdAt": "2021-01-15T18:15:52.324Z",
          "updatedAt": "2021-07-21T16:47:33.317Z",
          "environment": {
            "sys": {
              "id": "master",
              "type": "Link",
              "linkType": "Environment"
            }
          },
          "locale": "es"
        },
        "fields": {
          "title": "Bg-CA-jpg",
          "description": "Imagen de fondo en formato jpg",
          "file": {
            "url": "//images.ctfassets.net/ex6ts2p2j0ib/YVtejJOf50JZ8LNENHj1W/8b2bd16558f55348add6a740ae871f88/BG-Formulario-Cta-Ahorro-1-.jpg",
            "details": {
              "size": 70801,
              "image": {
                "width": 1440,
                "height": 640
              }
            },
            "fileName": "BG-Formulario-Cta-Ahorro[1].jpg",
            "contentType": "image/jpeg"
          }
        }
      }
    ],
    "tituloBeneficios": "¡Abre tu <strong>CMR</strong> ahora y haz tu primera </br>compra 100% online! ",
    "beneficio1": "<img alt=\"Compra en todo el mundo\" src=\"https://images.ctfassets.net/ex6ts2p2j0ib/5HDAuLjygWYIHLFyaT68KV/bd4df564e623e454665471d4ad904597/icn-cmr-digital.svg\" title=\"Mastercard\" class=\"benefit-icon\"><span>Compra ahora en cualquier comercio </br>online con el número de tarjeta </br>que encontrarás en tu App Banco Falabella </strong></span>",
    "beneficio2": "<img alt=\"oportunidad única\" src=\"https://images.ctfassets.net/ex6ts2p2j0ib/fAZzzkKBiw6zz3gFYK7gT/1515a92f9e6c76c39b9ec7ea0b095de4/oportunidad-unica.svg\" title=\"Obtén descuentos exclusivos\" class=\"benefit-icon\">\n<span>Aprovecha Oportunidades Únicas y ahorros </br>permanentes de hasta un 40% en Tiendas </br>Falabella, Homecenter, Makro </br>y establecimientos aliados</span>",
    "beneficio3": "<img alt=\"Acumula CMR Puntos\" src=\"https://images.ctfassets.net/ex6ts2p2j0ib/tfY09sAI7yq2Mfglp0EnI/5175fb9401a03a15aa112e74fc62cb81/ben-cmrpuntos.svg\" title=\"Acumula CMR Puntos\" class=\"benefit-icon\"><span>Acumulas hasta 4 veces más CMR Puntos que  </br>con otros medios de pago</span>",
    "faQs": [
      {
        "ID": "1",
        "TITLE": "¿Cuáles son los requisitos para poder abrir una tarjeta CMR Mastercard?",
        "RESPUESTA": "Las Tarjetas CMR tienen diferentes requisitos necesarios para su apertura, puedes revisar los requisitos de las Tarjetas CMR en <a href='https://www.bancofalabella.com.co' target='_blank' rel='noopener'>www.bancofalabella.com.co</a> en el menú 'Tarjetas CMR'"
      },
      {
        "ID": "2",
        "TITLE": "¿Cómo puedo obtener una tarjeta CMR Mastercard Banco Falabella?",
        "RESPUESTA": "Solicita tu tarjeta de crédito CMR completando el formulario y en pocos minutos tendrás disponible tu tarjeta digital para ocuparla al instante desde tu APP Banco Falabella. Si quieres conocer en detalle las tarjetas y beneficios de tu CMR Banco Falabella los puedes encontrar en <a href='https://www.bancofalabella.com.co/page/beneficios-tarjetas' target='_blank' rel='noopener'>https://www.bancofalabella.com.co/page/beneficios-tarjetas</a>, además podrás revisar los requisitos que se necesitan para obtenerla."
      },
      {
        "ID": "3",
        "TITLE": "¿Dónde puedo utilizar mi Tarjeta de Crédito CMR Banco Falabella?",
        "RESPUESTA": "Nuestra <strong>Tarjeta de Crédito CMR Banco Falabella</strong> hace parte de la franquicia Mastercard, con ella podrás hacer compras en todos los comercios a nivel a mundial, es aceptada para todas tus compras en cualquier tienda física, además, puedes hacer compras a través de cualquier comercio electrónico. Úsala cuando quieras, como quieras y como quieras."
      },
      {
        "ID": "4",
        "TITLE": "¿Cómo puedo ocupar mi tarjeta de crédito CMR al instante? ¿Cómo descargo mi App?",
        "RESPUESTA": "Toda la información de tu CMR está dentro de la APP Banco Falabella. Solo tienes que descargar la aplicación desde APP Store o <a href='https://play.google.com/store/apps/details?id=co.com.bancofalabella.mobile.omc' target='_blank' rel='noopener'>Google Play</a> y podrás visualizar todos los datos de tu tarjeta de crédito Mastercard para hacer compras por internet, acumular CMR puntos y revisar todos tus movimientos de tu tarjeta de crédito."
      },
      {
        "ID": "5",
        "TITLE": "¿Cómo puedo obtener mi CMR de forma física?",
        "RESPUESTA": "Al solicitar tu CMR online puedes utilizarla al instante sin la necesidad de salir de la comodidad de tu casa. De igual forma, puedes dirigirte a cualquier de nuestras sucursales Banco Falabella para que puedas retirar el plástico y realices tus compras en forma presencial o puedes agendar el envío a tu domicilio sin ningún costo por medio de nuestro WhatsApp en el siguiente enlace: <a href='https://www.bancofalabella.com.co/page/consultas-whatsapp' target='_blank' rel='noopener'>Consultas Whatsapp</a>"
      },
      {
        "ID": "6",
        "TITLE": "¿Cómo puedo aumentar el cupo de mi tarjeta?",
        "RESPUESTA": "Si necesitas aumentar el cupo de tus tarjetas CMR sólo tienes que solicitarlo y actualizar tu historial laboral, económico y/o financiero en cualquiera de las oficinas Banco Falabella o en las ubicadas en las tiendas Falabella o Sodimac (El cliente será evaluado en función de su comportamiento de pago y actualización de datos)."
      },
      {
        "ID": "7",
        "TITLE": "¿Qué pasa si es que tengo dudas o problemas con mi tarjeta CMR?",
        "RESPUESTA": "Ante cualquier inconveniente o duda que tengas en relación a tu tarjeta de crédito puedes contactarnos via WhatsApp en el siguiente enlace: <a href='https://www.bancofalabella.com.co/page/consultas-whatsapp' target='_blank' rel='noopener'>Consultas Whatsapp</a>. De igual modo, puedes encontrar todo lo que necesites en nuestra web <a href='https://www.bancofalabella.com.co/' target='_blank' rel='noopener'>www.bancofalabella.com.co</a> o desde nuestra App Banco Falabella."
      },
      {
        "ID": "8",
        "TITLE": "¿Cómo hago para cambiar la fecha de corte de mi Tarjeta de Crédito?",
        "RESPUESTA": "Podrás solicitar el cambio de la fecha de corte de tu Tarjeta Crédito en cualquier oficina del Banco Falabella, siempre y cuando tu Tarjeta de Crédito no presente saldo adeudado."
      },
      {
        "ID": "9",
        "TITLE": "¿Qué comisiones me cobra el Banco por el uso de mi Tarjeta de Crédito?",
        "RESPUESTA": "Por el uso de tu Tarjeta, se cobran las siguientes sumas: <ul><li>Cuota de manejo, si quieres validar la misma puedes encontrarla aqui: <a href='https://www.bancofalabella.com.co/tarjetas' target='_blank' rel='noopener'>ver detalle tarjetas</a></li><li>Por consulta y realización de avances.</li><li>Por transacciones declinadas en cajeros automáticos en razón a PIN errado y/o por cupo insuficiente para realizar avances.</li><li>Por solicitud de copias de vouchers de las compras realizadas con las Tarjetas de Crédito presentadas al Banco Falabella.</li><li>Gastos de cobranzas en caso de que haya lugar a estos.</li></ul>A través de <a href='https://www.bancofalabella.com.co/' target='_blank' rel='noopener'>www.bancofalabella.com.co</a> y/o información disponible en nuestras oficinas, podrás consultar las tarifas vigentes para cada una de las comisiones aquí señaladas"
      },
      {
        "ID": "10",
        "TITLE": "¿Cómo me vinculo con el programa CMR Puntos?",
        "RESPUESTA": "Al abrir tu tarjeta CMR Banco Falabella quedas inscrito en nuestro programa de CMR Puntos donde acumularás para luego canjear los mismos por diversos beneficios. Ademas acumula hasta 4 veces más CMR Puntos que con otros medios de pago al pagar con tu CMR."
      },
      {
        "ID": "11",
        "TITLE": "¿Cuáles son los beneficios de Aliados que tiene la CMR?",
        "RESPUESTA": "Puedes encontrar nuestras alianzas mas actualizadas en la siguiente <a href='https://www.bancofalabella.com.co/beneficios' target='_blank' rel='noopener'>sección</a> y que siempre contarás con descuentos permanentes en <a href='https://www.falabella.com.co/' target='_blank' rel='noopener'>Falabella</a> y <a href='http://www.homecenter.com.co/' target='_blank' rel='noopener'>Homecenter</a>."
      }
    ]
  },
  {
    "nombreSeccion": "Sección superior caFlow",
    "titulo": "Abre tu <strong class='green-text'>Cuenta de Ahorros Costo $0 </strong> en minutos y empieza a usarla de una",
    "background": [
      {
        "metadata": {
          "tags": []
        },
        "sys": {
          "space": {
            "sys": {
              "type": "Link",
              "linkType": "Space",
              "id": "ex6ts2p2j0ib"
            }
          },
          "type": "Asset",
          "id": "1aGCJ3prhbqnMR33mwG0Ec",
          "revision": 3,
          "createdAt": "2021-05-12T15:49:15.709Z",
          "updatedAt": "2022-08-22T19:12:18.688Z",
          "environment": {
            "sys": {
              "id": "master",
              "type": "Link",
              "linkType": "Environment"
            }
          },
          "locale": "es"
        },
        "fields": {
          "title": "Bg-jpg",
          "file": {
            "url": "//images.ctfassets.net/ex6ts2p2j0ib/1aGCJ3prhbqnMR33mwG0Ec/06a487fb22e35fb6277a44eefb6107c0/bg-img-cuenta-ahorros-web.jpg",
            "details": {
              "size": 145118,
              "image": {
                "width": 1280,
                "height": 720
              }
            },
            "fileName": "bg-img-cuenta-ahorros-web.jpg",
            "contentType": "image/jpeg"
          }
        }
      },
      {
        "metadata": {
          "tags": []
        },
        "sys": {
          "space": {
            "sys": {
              "type": "Link",
              "linkType": "Space",
              "id": "ex6ts2p2j0ib"
            }
          },
          "type": "Asset",
          "id": "3hTjlqdEGYQqjfn58cGDPn",
          "revision": 1,
          "createdAt": "2021-05-12T15:49:15.708Z",
          "updatedAt": "2022-06-15T20:21:21.115Z",
          "environment": {
            "sys": {
              "id": "master",
              "type": "Link",
              "linkType": "Environment"
            }
          },
          "locale": "es"
        },
        "fields": {
          "title": "Bg-webp",
          "file": {
            "url": "//images.ctfassets.net/ex6ts2p2j0ib/3hTjlqdEGYQqjfn58cGDPn/ec0f545a08ce108e58e71b95e8ac1126/ahorros.webp",
            "details": {
              "size": 825556,
              "image": {
                "width": 1280,
                "height": 640
              }
            },
            "fileName": "ahorros.webp",
            "contentType": "image/webp"
          }
        }
      }
    ],
    "tituloBeneficios": "¡Una <strong>Cuenta de Ahorros </strong>para nómina e independientes con beneficios todos los días!",
    "beneficio1": "<img alt=\"Costo $0 de verdad\" src=\"https://images.ctfassets.net/ex6ts2p2j0ib/3Rtt6TEltIaGQ5edZoUv9X/d2fd68394f939c1cf8b294d52f69a102/Costo-0-icono-OB-ahorros.png\" title=\"Costo $0 de verdad\" class=\"benefit-icon\"><span><strong>Costo $0 de verdad</strong><br />Sin cuota de manejo, transferencias gratis <br />e ilimitadas a cuentas de otros bancos, retiros gratis en más <br />de 2.600 cajeros Servibanca y hasta 3 retiros gratis al mes  <br />en cajeros de cualquier banco",
    "beneficio2": "<img alt=\"Cientos de Oportunidades Únicas\" src=\"https://images.ctfassets.net/ex6ts2p2j0ib/7Eo5hLK7sdlnivJzd9CEgD/8006e00d192baa373f46bad41952bc55/OUS-icono-OB-ahorros.png?\" title=\"Cientos de Oportunidades Únicas\" class=\"benefit-icon\"><span><strong>Cientos de Oportunidades Únicas</strong><br />Descuentos todos los días en Homecenter, <br />Falabella, Falabella.com y en más de 40 comercios aliados",
    "beneficio3": "<img alt=\"Acumula CMR Puntos​ con cada compra\" src=\"https://images.ctfassets.net/ex6ts2p2j0ib/tfY09sAI7yq2Mfglp0EnI/5175fb9401a03a15aa112e74fc62cb81/ben-cmrpuntos.svg\" title=\"Acumula CMR Puntos​ con cada compra\" class=\"benefit-icon\"><span><strong>Acumula CMR Puntos​ con cada compra​</strong><br />¡Usa tu Tarjeta Débito para lo que quieras y aprovecha todo lo que<br /> puedes redimir con tus puntos​!</span>",
    "faQs": [
      {
        "ID": "1",
        "TITLE": "¿Cuáles son los requisitos para poder abrir una tarjeta CMR Mastercard?",
        "RESPUESTA": "Las Tarjetas CMR tienen diferentes requisitos necesarios para su apertura, puedes revisar los requisitos de las Tarjetas CMR en <a href='https://www.bancofalabella.com.co' target='_blank' rel='noopener'>www.bancofalabella.com.co</a> en el menú 'Tarjetas CMR'"
      }
    ]
  },
  {
    "nombreSeccion": "Beneficios",
    "tituloBeneficios": "¿Por qué tener una <strong class=\"green-text\">Tarjeta de Crédito CMR MasterCard Banco Falabella</strong>?",
    "beneficio1": "<img alt=\"oportunidad única\" src=\"https://images.ctfassets.net/ex6ts2p2j0ib/fAZzzkKBiw6zz3gFYK7gT/1515a92f9e6c76c39b9ec7ea0b095de4/oportunidad-unica.svg\" title=\"Obtén descuentos exclusivos\">\n<h4>Oportunidades Únicas y Descuentos</h4>\n<p>Hasta un 40% en Tiendas Falabella, Homecenter, Makro y establecimientos aliados. <a \n alt=\"enlace a beneficios\" href=\"https://www.bancofalabella.com.co/beneficios\" target=\"_blank\" alt=\"enlace a beneficios\" rel=\"noopener\">Conoce más aquí</a></p>",
    "beneficio2": "<img alt=\"Acumula CMR Puntos\" src=\"https://images.ctfassets.net/ex6ts2p2j0ib/tfY09sAI7yq2Mfglp0EnI/f01c3b6efd55188f7fb8e1556928cd27/ben-cmrpuntos.svg\" title=\"Acumula CMR Puntos\"><h4>Acumulas más CMR Puntos</h4><p>1 punto cada $2000 por compras en cualquier comercio o cada $1000 por compras en Falabella, Home Center y comercios asociados.</p>",
    "beneficio3": "<img alt=\"Utilízala en todos lados\" src=\"https://images.ctfassets.net/ex6ts2p2j0ib/7sSdW6tHevFf73sBh6XelM/43ded579f82ef5ceebbd09ec7f91daa1/master-card.svg\" title=\"Mastercard\"><h4>Utilízala en todos lados</h4><p>Aceptada en establecimientos de comercio en Colombia y en el mundo.</p>"
  }
];