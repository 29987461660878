import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StorageHandlerModule } from 'cob-infrastructure';
import { HeaderComponent } from './components/header/header.component';
import { LoginAvatarComponent } from './components/login-avatar/login-avatar.component';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { ModalLoaderComponent } from './components/modal-loader/modal-loader.component';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { RetailDataHandler } from '@shared/handlers/retail/retail-data.handler';
import { BlobHandler } from './handlers/blob/blob.handler';
import { ProductOverviewComponent } from './components/product-overview/product-overview.component';
import { LoaderClientHandler } from './handlers/client/loader-client.handler';
import { ProductHandler } from './handlers/product/product.handler';
import { ModalComponent } from './components/modal/modal.component';
import { ComparisonComponent } from '@shared/components/comparison/comparison.component';
import { CrossingListComponent } from './components/crossing-list/crossing-list.component';

@NgModule({
  imports: [CommonModule, StorageHandlerModule],
  declarations: [
    HeaderComponent,
    ModalLoaderComponent,
    LoginAvatarComponent,
    ModalInfoComponent,
    ProgressCircleComponent,
    ProductOverviewComponent,
    ModalComponent,
    ComparisonComponent,
    CrossingListComponent
  ],
  exports: [
    HeaderComponent,
    ModalLoaderComponent,
    LoginAvatarComponent,
    ModalInfoComponent,
    ProgressCircleComponent,
    ProductOverviewComponent,
    ModalComponent,
    ComparisonComponent,
    CrossingListComponent
  ],
  providers: [RetailDataHandler, BlobHandler, LoaderClientHandler, ProductHandler],
})
export class SharedModule { }
