<div class="crossing-products">
  <ng-container *ngFor="let product of products">
    <div *ngIf="useBlack && product.showOptionalMessagePassive" class="text-center">
      <button id="compare-modal" (click)="clgEvent('hiddenBlackModal')" class="btn btn-link green-text text-capitalize">
        Comparar tarjetas <span class="arrow"></span>
      </button>
    </div>
    <p class="optional-text" *ngIf="product.showOptionalMessageTC">Elige una tarjeta (OPCIONAL)</p>
    <p class="optional-text" *ngIf="product.showOptionalMessagePassive">Elige hasta dos cuentas (OPCIONAL)</p>
    <div class="card ob-card text-center">
      <div [id]="product.productName" class="card-header" (click)="handleSelection(product.productName)">
        <div class="d-flex bd-highlight align-items-center">
          <div *ngIf="product.thumbnail" class="product-thumb mr-4 bd-highlight">
            <img class="" [src]="product.thumbnail"/>
          </div>
          <div class="bd-highlight">
            <p class="product-name">{{ product.productTitle }}</p>
          </div>
          <div class="ml-auto bd-highlight check">
            <label class="form-control">
              <input id="check-CA" type="checkbox" [checked]="crossing[product.productName]" name="radio"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card-body active">
        <div class="attrs row">
          <ng-container *ngFor="let attr of product.attrs">
            <div class="col-12 mb-2">
              <div [ngClass]="{'background': attr.withColor, 'total': attr.code === 'FGAT'}" class="row justify-content-between product-attrs">
                <div class="col align-self-center pr-1">
                  <p class="label text-left">
                    {{attr.title}}
                  </p>
                </div>
                <div class="col-auto align-self-center pl-1">
                  <p class="value text-right">{{attr.value}}</p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="product.captions.length > 0" class="captions">
          <div *ngFor="let caption of product.captions" class="row justify-content-between">
            <div class="col-12">
              <p class="label text-left">
                {{caption}}
              </p>
            </div>
          </div>
          <hr style="width: 100%;">
        </div>

        <ul class="benefits">
          <li *ngFor="let benefit of product.productBenefits"><span [innerHTML]="benefit.value"></span></li>
        </ul>
        <div  *ngIf="product.showFga" class="what-is-fga">
          <p>Tu CMR estará respaldada por el <b>FGA Fondo de Garantías. </b><button id="fga-modal" (click)="clgEvent('hiddenModal')" class="green-text">¿Qué es el FGA?</button></p>
        </div>
      </div>
      <div class="card-footer text-muted">
        <button [id]="'toggle'+product.productName" class="btn btn-link active" (click)="toggleColl($event)">
          Ver menos
          <img class="expand" src="{{staticAssetsUrl}}assets/img/icn_green_arrow_d.png" alt="icono-expandir">
        </button>
      </div>
    </div>
  </ng-container>
</div>

<div id="modal-info" class="modal-info fga-modal" [hidden]="hiddenModal">
  <div class="white-content" #simplebar>
    <div class="header">
      <div id="close-fga-modal" class="close" (click)="clgEvent('hiddenModal')"><img src="{{staticAssetsUrl}}assets/img/icn-cross.svg" class="close-btn" alt="" /></div>
      <p class="text-center">¿Qué es el FGA?</p>
    </div>
    <div class="body">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <img class='logo-fga-modal' alt='logo' src='{{staticAssetsUrl}}assets/img/logo-fga-fondo.png'>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="question">
            ¿Qué es el FGA Fondo de Garantías?
          </p>
          <p>
            Es una entidad que actúa como fiador para tu Tarjeta CMR, gracias a este respaldo podemos entregarte una tarjeta, y hace posible que más personas como tú, puedan acceder a créditos.
          </p>
          <p>
            En caso de incumplimiento en el pago de tu deuda, ellos se encargarán de realizar el cobro y reporte a las centrales de riesgo correspondientes.
          </p>
        </div>
        <div class="col-12">
          <p class="question">
            ¿Cómo funciona?
          </p>
          <p>
            Al aceptar una tarjeta con garantía FGA, verás en tu extracto el cobro de una comisión que se calcula según tu cupo inicial. Esta comisión estará exenta de intereses y se diferirá a 12 meses.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
