export enum SessionKeys {
  ADDITIONAL_DATA = 'additional-data',
  CLIENTS_INTENTION = 'clientsIntention',
  MORE_INFO = 'moreInfo',
  EMPLOYMENT_INFO = 'employment-info',
  EXTRA_FIELDS = 'extraField',
  FLOW_TYPE = 'flowType',
  CHANNEL_TYPE = 'channelType',
  USERNAME = 'username',
  SECOND_EVALUATE = 'second',
  CONTENT = 'content',
  PRODUCTS = 'products',
  PERSONAL_DATA = 'personal-data',
  PEP_FATCA = 'pep-fatca',
  IS_TC = 'is-tc',
  IS_PAC= 'is-pac',
  IS_CA = 'is-ca',
  REQUESTED_PRODUCTS = 'requested-products',
  IS_FRAUD = 'is-fraud',
  NEXT_PAGE_AFTER_UPLOAD_ID = 'next-page-after-upload-id',
  SALARY = 'salary',
  FLOWSESSIONID = 'flowSessionId',
  PASSWORD_CREATED = 'password-created',
  NAME_RETOMA = 'name-retoma',
  IS_RESUME = 'is-resume',
  RESUME_DATA = 'resume-data',
  SIGNATURE_FAILED = 'signature-failed',
  PRODUCT_CREATION_FAILED = 'product-creation-failed',
  PRODUCT_CREATION_FAILED_CONTINGENCY = 'product-creation-failed-contingency',
  GO_TO_THE_REGISTRY = 'go-to-the-registry',
  OFFICE_CODE = 'office-code',
  IS_CA_FLOW = 'is-ca-flow',
  ACCOUNT_NUMBER = 'account-number',
  CLONE_APPLICATION = 'clone-application',
  REGISTRY_SUCCESS = 'registry-success',
  LOGIN_TYPE = 'loginType',
  EXPIRES_IN = 'expires_in',
  EXPIRES_IN_DATE = 'expires_in_date',
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  SIGNATURE_RETRIES = 'signature-retries',
  INSURANCE_SELECTION = 'insurance-selection',
  PRINT_DOCUMENTS = 'print-documents',
  CONTRACT_CUP = 'contract_cup',
  CONTRACT_SV = 'contract_sv',
  CONTRACT_PCI = 'contract_pci',
  CONTRACT_PROD_INSURANCE = 'contract_prod_insurance',
  IS_FGA = 'isFga',
  TC_CREATED = 'tc-created',
  CA_CREATED = 'ca-created',
  PAC_CREATED = 'pac-created',
  ENABLE_INSURANCE_MULTI = 'enable-insurance-multi',
  PRODUCT_CROSSING = 'product-crossing',
  IS_MULTI_DIGITAL = 'is-multi-digital',
  ONLY_MONO = 'only-mono',
  FACEID_RETRIES = 'fi-retries',
  OBLIGATORY_INSURANCE = 'obligatory-insurance'
}
