import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { SessionKeys } from '@shared/domain/session-keys';
import { SessionStorageHandler } from 'cob-infrastructure';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  staticAssetsUrl = environment.staticAssetsUrl;
  constructor(
    private router: Router,
    private sessionStorageHandler: SessionStorageHandler) {
  }

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        history.pushState(null, null, location.href);
        window.onpopstate = () => {
          history.go(1);
        };
      }
    });
  }

  public diplayMenu(localWindow = (window as any)) {
    if (typeof localWindow.diplayMenu === 'function') {
      localWindow.diplayMenu();
    }
  }

  public isAnonymousUser(): boolean {
    const username = this.sessionStorageHandler.getValue(SessionKeys.USERNAME);
    const bool = (typeof username === 'undefined' ||
    username === null ||
    username.length === 0 ||
    username === 'anonymous');
    if (!bool) {
      document.getElementById('menu-hamburguesa').hidden=true;
    }
    return bool;
}

}
