<div *ngIf="!hide" class="progress-circle" [style.display]="label ? 'inline-flex' : 'block'">
  <div>
    <div class="progress green">
      <span class="progress-left">
        <span class="progress-bar" [style.animation]="animationLeft"></span>
      </span>
      <span class="progress-right">
        <span class="progress-bar" [style.animation]="animationRight"></span>
      </span>
      <div class="progress-value">{{timeLeft}}</div>
    </div>
  </div>
  <div *ngIf="label" style="margin-left: 5px;">{{label}}</div>
</div>