import { Flow } from '@shared/enums/utils.enum';
import { environment } from '@environment/environment';
import { SessionKeys } from '@shared/domain/session-keys';
import { SessionStorageHandler } from 'cob-infrastructure';
import { Analytics } from 'fif-common-analytics';
export class CommonFunctions {
  public static isDesktopDevice = (): boolean => {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua) ? false : true;
  }
  
  public static popupWindowCenter = ({url, title, w, h}) => {

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const left = (width/2)-(w/2);
    const newWindow = window.open(url, title, 
      `
      scrollbars=yes,
      resizable=yes,
      menubar=no,
      titlebar=no,
      toolbar=no,
      location=no,
      status=no,
      width=${w}, 
      height=${h}, 
      top=${400}, 
      left=${left},
      `
      )

    if (window.focus) newWindow.focus();
    return newWindow;
  }

  public static formatNumber = (num) => {
    return ('$' + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')).split(' ')[0]
  }

  public static isTc = (products) => {
    const lisTc = ['MASTERCARD', 'CERRADA', 'BLACK'];
    return lisTc.some((tc) =>  products.includes(tc));
  }

  public static getFlow(flow): Flow {
    return environment.app.appFlow === 'retail' ? Flow.RetailTC : flow
  }

  public static setGAMark(sessionStorageHandler: SessionStorageHandler, taggingAdapter: Analytics.TaggingAdapter, screenEvent: string, event: string) {
    const products = {
      MASTERCARD: { name:'cmr', variant:'cmr_mastercard_gold' },
      BLACK: { name:'cmr_black', variant:'cmr_mastercard_black' },
      CA: { name:'cuenta ahorro', variant:'cuenta_ahorro_costo_0' },
      PAC: { name:'cuenta ahorro PAC',variant:'cuenta_ahorro_pac' }
    };
    const productsSelected = JSON.parse(sessionStorageHandler.getValue(SessionKeys.REQUESTED_PRODUCTS) || '[]');
    const isMultiDigital = sessionStorageHandler.getValue(SessionKeys.IS_MULTI_DIGITAL);
    const productList = productsSelected.map((product) => {
      return products[product];
    });
    const aditionalAttrs = {isMultiDigital, product_list: JSON.stringify(productList)};
    taggingAdapter.tagEvent('event', screenEvent, event, aditionalAttrs);
  }

  public static getTextOfCheck(sessionStorageHandler: SessionStorageHandler, SIGNATURE: any) {
    const isTc = sessionStorageHandler.getValue(SessionKeys.IS_TC) === 'true';

    if(isTc) {
      const requestedProducts = JSON.parse(sessionStorageHandler.getValue(SessionKeys.REQUESTED_PRODUCTS) || '[]');
      const obligatoryInsurance = sessionStorageHandler.getValue(SessionKeys.OBLIGATORY_INSURANCE) === 'true' && requestedProducts.includes('MASTERCARD');
      return obligatoryInsurance ? SIGNATURE.FORM_ACCEPT_CONTRACT_WITH_INSURANCE : SIGNATURE.FORM_ACCEPT_CONTRACT;
    }
    return SIGNATURE.FORM_ACCEPT_CONTRACT_ISCAFLOW;
  }
}
