import { Injectable } from '@angular/core';
import { GeneralConfigService } from '@shared/services/general-config-service';
import { IsUserService } from '@shared/services/is-user.service';
import { InvokerService } from 'cob-infrastructure';

@Injectable()
export class ProductHandler {

  constructor(
    private generalConfigService: GeneralConfigService,
    private isUserService: IsUserService,
    private service: InvokerService) { }

  /**
   * @param digits otp 6 digits
   */
  public sign(body: any): Promise<any> {
    return this.service.post(
      body, this.generalConfigService.getApiUrl('service_linkage_signature_sign'),
      { class: 'ProductHandler', method: 'sign' });
  }

  public productCreate(product = null): Promise<any> {
    const prod = {product: product};
    const url = 'service_linkage_product_create_multi';
    return this.service.post(prod, this.generalConfigService.getApiUrl(url),
      { class: 'ProductHandler', method: 'productCreate' });
  }

  public insuranceSuscription(insurances: any[]): Promise<any> {
    const selectedIdInsurances = insurances.map((insurance) => ({ id: insurance.id }));
    const body = {
      insurances: selectedIdInsurances,
    };
    return this.service.post(body, this.generalConfigService.getApiUrl('service_linkage_insurance_suscription'),
      { class: 'ProductHandler', method: 'insuranceSuscription' });
  }
}
