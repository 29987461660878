<div id="modal-loader" class="modal-loader" [hidden]="!showModal">
  <div class="mloader-container" [ngClass]="{'with-text': withText}">
    <div class="content-loader">
      <ng-container *ngIf="!withText">
        <div class="loader" id="loader-1">
          <img src="{{staticAssetsUrl}}assets/img/paltas.png" class="logo-paltas" />
        </div>
      </ng-container>
      <ng-container *ngIf="withText">
        <p class="title" [innerHTML]="text.title"></p>
        <p class="description" [innerHTML]="text.description"></p>
        <div class="loader" id="loader-1">
          <img src="{{staticAssetsUrl}}assets/img/paltas.png" class="logo-paltas" />
        </div>
      </ng-container>
    </div>
  </div>
</div>