<div id="product-overview" class="row">
  <div class="col-12 mb-3" *ngFor="let product of products">
    <div class="card ob-card product-card">
      <div [id]="product.productName" class="card-header">
        <div class="d-flex align-items-center">
          <div *ngIf="product.thumbnail" class="product-thumb">
            <img class="" [src]="product.thumbnail"/>
          </div>
          <div class="ml-3">
            <p class="product-name">{{ product.name }}</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div *ngFor="let attr of product.attrs" class="row justify-content-between product-attrs">
          <div class="col align-self-center pr-1">
            <p class="label text-left">
              {{attr.title}}
            </p>
          </div>
          <div class="col-auto align-self-center pl-1">
            <p class="value text-right"><b>{{attr.value}}</b></p>
          </div>
        </div>
      </div>
      <div *ngIf="product.captions.length > 0" class="card-footer">
        <div *ngFor="let caption of product.captions" class="row justify-content-between">
          <div class="col-12">
            <p class="text-left">
              {{caption}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>