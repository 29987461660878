import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalInfoComponent implements OnInit {
  contentHTML: any
  @Input() buttonTitle;
  @Input()
  public content: any;
  @Input()
  public withoutHeader: boolean = false;

  @Output()
  public togglePressed: EventEmitter<any> | any = new EventEmitter<any>();

  hidden = true;
  staticAssetsUrl = environment.staticAssetsUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
  }

  ngOnChanges(obj) {
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.content);
  }

  getButtonLabel(){
    if(this.buttonTitle === null || this.buttonTitle === undefined || this.buttonTitle === '') {
      return 'Aceptar'
    }
    return this.buttonTitle;
  }

  toggle() {
    const body = document.getElementsByTagName('body')[0];
    this.hidden = !this.hidden;
    if (this.hidden) {
      body.classList.remove('modal-open');
      this.togglePressed.emit('close-modal');
      document.getElementById('modal-info').scrollTop = 0;
    } else {
      body.classList.add('modal-open');
      this.togglePressed.emit('open-modal');
    }
  }
}
