import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonFunctions } from '@shared/functions/common.functions';
import { SessionStorageHandler } from 'cob-infrastructure';
import { ContentfulService } from '@core/services/contentful/contentful.service';
import { SessionKeys } from '@shared/domain/session-keys';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-crossing-list',
  templateUrl: './crossing-list.component.html',
  styleUrls: ['./crossing-list.component.scss'],
})
export class CrossingListComponent implements OnInit {
  @Input() products;
  @Input() crossing;
  @Output() hideModal: EventEmitter<any> = new EventEmitter();
  expression: boolean = false;
  anyTc: boolean = false;
  useBlack: boolean = false;
  showPassive: boolean = false;
  hiddenModal = true;
  staticAssetsUrl = environment.staticAssetsUrl;
  public static TCNAMES = ['CERRADA', 'MASTERCARD', 'BLACK','TC'];

  constructor(
    private sessionStorageHandler: SessionStorageHandler,
    private contentFullService: ContentfulService
  ) {}

  ngOnInit() {
    this.products = this.products || this.getRequestedProducts();
    this.useBlack = this.products.some((product) => (product.productName === 'BLACK'));
  }

  getRequestedProducts = () => {
    const { crossing, offer, detail, extraField, enableFGA } = JSON.parse(this.sessionStorageHandler.getValue(SessionKeys.SECOND_EVALUATE));
    const isFga = this.determineFga({ detail, extraField, enableFGA });
    const allProducts = [ ...offer.slice(1), ...crossing ];
    const contentfulProducts = this.contentFullService.getProductsContent();
    const products = contentfulProducts.filter((product) => (allProducts.includes(product.productName)));
    const costs = ['CM','CD','TI','FGA','FGAT'];
    const obligatoryInsurance = this.sessionStorageHandler.getValue(SessionKeys.OBLIGATORY_INSURANCE) === 'true';
    const captionOptions = ['caption'];
    if(obligatoryInsurance) {
      costs.push('CSO');
      captionOptions.push('captionInsurance');
    }

    let showOptionalMessageTC = false;
    let showOptionalMessagePassive = false;
    return products.map((product) => {
      const {productName, productTitle, productBenefits, offer: { thumbnail }} = product;
      let attrs = product.productCost.filter((cost) => (costs.includes(cost.code)));
      let showFga;
      if(CrossingListComponent.TCNAMES.includes(productName)) {
        const amountGranted = detail[product.productName].montoOtorgado;
        attrs.find((attr) => (attr.code === 'CD')).value = CommonFunctions.formatNumber(amountGranted);
        showOptionalMessageTC = !showOptionalMessageTC && true;
        const fgaAttrs = { FGA: 'Valor12Meses', FGAT: 'ValorComisionIVA' };
        if(isFga) {
          showFga = isFga;
          captionOptions.push('caption_fga');
          captionOptions.shift();
          Object.keys(fgaAttrs).forEach((fgaKey) => {
            const value = extraField.find((field) => field.nombreAtributo === fgaAttrs[fgaKey]).valorAtributo;
            if (fgaKey === 'FGAT') {
              const oldAttr = attrs.find((attr) => (attr.code === fgaKey));
              oldAttr.title = oldAttr.title.replace(/xxxxxx/g, CommonFunctions.formatNumber(value));
            } else {
              attrs.find((attr) => (attr.code === fgaKey)).value = CommonFunctions.formatNumber(value);
            }
          });
        } else {
          attrs = attrs.filter((attr) => ( !Object.keys(fgaAttrs).includes(attr.code) ));
        }
      } else {
        showOptionalMessageTC = false;
        showOptionalMessagePassive = !showOptionalMessagePassive && true;
      }

      const leakedCaptions = captionOptions.filter((attr) => (Object.keys(product.offer).includes(attr)));
      const captions = leakedCaptions.map(attr => (product.offer[attr]));

      return { productName, productTitle, attrs, thumbnail, productBenefits, showFga, showOptionalMessageTC, showOptionalMessagePassive, captions };
    });
  }

  handleSelection = (product) => {
    const element = document.getElementById(product);
    const parent = element.parentElement
    this.crossing[product] = !this.crossing[product];
    element.classList.toggle('active');
    parent.classList.toggle('active');
    if (CrossingListComponent.TCNAMES.includes(product)) {
      if (this.crossing[product]) {
        CrossingListComponent.TCNAMES.forEach((tc) => {
          if (this.crossing[tc] && tc !== product) {
            this.crossing[tc] = false; 
            const _element = document.getElementById(tc);
            const _parent = _element.parentElement
            _element.classList.toggle('active');
            _parent.classList.toggle('active');
          }
        });
      }
    }
  }

  toggleColl = (e) => {
    const element = e.currentTarget;
    const sibling = element.parentElement.previousSibling;
    element.classList.toggle('active');
    sibling.classList.toggle('active');
  }

  clgEvent = (event) => {
    if(event === 'hiddenBlackModal') this.hideModal.emit();
    else this[event] = !this[event];
  }

  determineFga = ({ detail, extraField, enableFGA }) => {
    let isFga = this.sessionStorageHandler.getValue(SessionKeys.IS_FGA) === 'true';
    if(isFga) {
      return isFga;
    } else {
      const products = Object.keys(detail);
      const any2000 = products.some((product) => {
        return parseInt(detail[product].codigoSubProducto) === 2000
      });
      const checkFgaAttrs = ['ValorComisionIVA', 'Valor12Meses'].every((key) => extraField?.find((attr) => ( key === attr['nombreAtributo']) && attr['valorAtributo']) );
      isFga = (enableFGA === 'true' && any2000 && checkFgaAttrs);
      this.sessionStorageHandler.setValue(SessionKeys.IS_FGA, JSON.stringify(isFga));
      return isFga;
    }
  }
}
