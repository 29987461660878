import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '@core/services/contentful/contentful.service';
import { DatadogService } from "@shared/services/datadog.service";
import { environment } from '@environment/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  constructor(
    private datadogService: DatadogService,
    private contentFullService: ContentfulService,
    ) {
  }
  private newConfig = {};

  async loadDatadog(){
    this.datadogService.loadScript(this.newConfig);
  }

  ngOnInit(){
    this.callDataDogConfigFromContentful();
  }

  private callDataDogConfigFromContentful() {
    this.contentFullService.dataDogConfig().then((response) => {
      const configName = environment.dataDogConfigName;
      const newConfig = response.find((config) => (config.name === configName)) || { config: {} };
      this.newConfig = newConfig.config || {};
      this.loadDatadog();
    }).catch((error) => {
      this.loadDatadog();
    });
  }

}
