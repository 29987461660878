import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class BlobHandler {

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
  }

  public generateBlob(url): Promise<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
    }).toPromise();
  }

  async onShowPDF(isMobile: boolean, url: string, filename: string): Promise<void> {
    const a = document.createElement('a');
    const blob = await this.generateBlob(url);
    if (isMobile) {
      const reader = new FileReader();
      const out = new Blob([blob], { type: 'dataType' });
      reader.onload = (e) => {
        window.location.href = url as string;
      };
      const fileURL = URL.createObjectURL(out);
      a.href = fileURL;
      a.target = '_blank';
      a.download = filename + '.pdf';
      document.body.appendChild(a);
      a.click();
    } else {
      a.setAttribute('href', url);
      a.setAttribute('target', '_blank');
      a.click();
    }
  }
}
