import { Component, OnInit, Input } from '@angular/core';
import { CompareCards } from '@shared/enums/product-card.enum'


@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit {
  @Input() products: any[] = [];

  allBenefits: any = [];
  blackBenefits: any = [];
  mastercardBenefits: any = [];
  productsCard: any = [];
  colors = {
    mastercard: '#ffffff',
    black: '#ffffff'
  };
  disclaimer: '';

  constructor() { }

  ngOnInit(): void {
    this.productInit();
  }

  productInit() {
    this.productsCard = this.products.filter(product => (Object.keys(CompareCards).includes(product.productName))) || [];
    const blackCard = this.productsCard.find(product => product.productName === CompareCards.BLACK) || {};
    const masterCard = this.productsCard.find(product => product.productName === CompareCards.MASTERCARD) || {};
    this.blackBenefits = new Set((blackCard.comparison?.comparator?.benefits || []).map((benefit) => (JSON.stringify(benefit))));
    this.mastercardBenefits = new Set((masterCard.comparison?.comparator?.benefits || []).map((benefit) => (JSON.stringify(benefit))));
    const data = new Set([...this.blackBenefits, ...this.mastercardBenefits]);
    this.allBenefits = [...data];
    this.productsCard.forEach((product) => {
      switch (product.productName) {
        case CompareCards.BLACK:
          this.colors.black = product.comparison?.comparator?.color;
          break;
        case CompareCards.MASTERCARD:
          this.colors.mastercard = product.comparison?.comparator?.color;
          break;
      }
    });
    this.disclaimer = blackCard.comparison?.comparator?.disclaimer;
  }

  parseBenefit(benefit) {
    return JSON.parse(benefit);
  }
}
