import { Injectable } from '@angular/core';
import { SessionKeys } from '@shared/domain/session-keys';
import { DatadogService } from '@shared/services/datadog.service';
import { GeneralConfigService } from '@shared/services/general-config-service';
import { InvokerService, SessionStorageHandler } from 'cob-infrastructure';
import { Analytics } from 'fif-common-analytics';

@Injectable()
export class LoaderClientHandler {
  constructor(
    private service: InvokerService,
    private generalConfigService: GeneralConfigService,
    private taggingAdapter: Analytics.TaggingAdapter,
    private sessionStorage: SessionStorageHandler,
    private datadog: DatadogService
  ) { }

  public async sendToClient(clientData): Promise<any> {
    const response = await this.service.post({ documentId: clientData.documentId },
    this.generalConfigService.getApiUrl('service_client_create'), { class: 'LoaderClientHandler', method: 'sendToClient' }, {}, false);
    return response;
  }

  public createClient = async(screenEvent: string) => {
    const data = JSON.parse(this.sessionStorage.getValue('person'));
    let nextPage = 'noClientCreation';
    return this
      .sendToClient(data)
      .then((response) => {
        if (response.code === 'ok') {
          nextPage = 'successfulClientCreation';
        } else if (response.abr === 'RED') {
          const isFail = response.code === 'fail';
          this.sessionStorage.setValue('failedProcessCode', 'FAILEDPROCESS.REJECTED_NOT_CLIENT');
          this.taggingAdapter.tagEvent(
            'error',
            screenEvent,
            `createClient${isFail ? '-fail' : ''}`,
            null
          );
        } else if (response.code === 'fail' && String(response.isFraud) === 'true') {
          this.sessionStorage.setValue(SessionKeys.IS_FRAUD, String(response.isFraud));
          this.datadog.addCustomEvent('auth-onboarding-fraude-seon', { abr: response.abr });
          this.taggingAdapter.tagEvent('error', screenEvent, 'createClient-fail', { abr: response.abr });
          nextPage = response.abr === 'ORANGE' ? 'faceId' : 'isFraud';
        } else {
          const isFail = response.code === 'fail';
          this.sessionStorage.setValue('failedProcessCode', 'FAILEDPROCESS.GENERIC_ERROR');
          this.taggingAdapter.tagEvent(
            'error',
            screenEvent,
            `createClient${isFail ? '-fail' : ''}`,
            null
          );
        }
        return nextPage;
      })
      .catch((error) => {
        this.sessionStorage.setValue('failedProcessCode', 'FAILEDPROCESS.GENERIC_ERROR');
        this.taggingAdapter.tagEvent('error', screenEvent, 'createClient', null);
        return nextPage;
      });
  };
}
