import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input()
  hidden:boolean = true;
  @Output()
  togglePressed: EventEmitter<string> = new EventEmitter<string>();
  staticAssetsUrl = environment.staticAssetsUrl;
  constructor() { }

  ngOnInit(): void {
  }

  toggle(source:string) {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
    this.hidden = !this.hidden;
    if (this.hidden) {
      body.classList.remove('modal-open');
      this.togglePressed.emit(source);
    }
  }

}
