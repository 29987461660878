<ng-container>
  <div class="row justify-content-md-center card-container">
    <div class="col-md-5 col-6 align-self-end products" *ngFor="let product of productsCard">
      <p class="title">{{product.comparison.comparator.title}}</p>
      <p class="cost">{{product.comparison.comparator.cost}}</p>
      <img class="thumb" [src]="product.comparison.comparator.thumb" alt="Miniatura de la Tarjeta">
      <span [style.background-color]="product.comparison.comparator.color" class="dot-color"></span>
    </div>
  </div>
  <div class="benefits-container">
    <div *ngFor="let benefit of allBenefits" class="row">
      <div class="col-1 offset-md-1 align-self-center" >
        <span *ngIf="mastercardBenefits.has(benefit)" [style.background-color]="colors.mastercard" class="dot-color"></span>
      </div>
      <div class="col-1 align-self-center">
        <span *ngIf="blackBenefits.has(benefit)" [style.background-color]="colors.black" class="dot-color"></span>
      </div>
      <div class="col-9 align-self-center benefit">
        <p class="primary align-middle">{{parseBenefit(benefit).primary}}</p>
        <p *ngIf="parseBenefit(benefit).secondary" class="secondary align-middle" [innerHTML]="parseBenefit(benefit).secondary"></p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 disclaimer">
        <p *ngIf="disclaimer" [innerHTML]="disclaimer"></p>
      </div>
    </div>
  </div>
</ng-container>
