import { Injectable } from '@angular/core';
import { SessionKeys } from '@shared/domain/session-keys';
import { SessionStorageHandler } from 'cob-infrastructure';

@Injectable({
  providedIn: 'root'
})
export class IsUserService {

  constructor(
    private sessionStorageHandler: SessionStorageHandler,
  ) { }

  public isUser = (): boolean => {
    const username = this.sessionStorageHandler.getValue(SessionKeys.USERNAME);
    return (
      typeof username !== 'undefined' &&
      username !== null &&
      username.length > 0 &&
      username !== 'anonymous'
    );
  };
}
