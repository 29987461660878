<div id="modal-info" class="modal-info" [hidden]="hidden" [ngClass]="{'new-style': withoutHeader}">
  <div class="white-content" #simplebar>
    <div *ngIf="!withoutHeader" class="header">
      <div class="close" (click)="toggle()"><img src="{{staticAssetsUrl}}assets/img/icn-cross.svg" class="close-btn" alt="" /></div>
      <img class='logo-falabella-modal' alt='logo' src='{{staticAssetsUrl}}assets/img/logo-banco-falabella.svg'>
    </div>
    <div class="body" [innerHTML]="content"></div>
    <div class="modal-footer modal-footer-mobile">
      <button class="btn btn-sm btn-secondary" type="button" id="modal-dialog-button" (click)="toggle()">
        {{ getButtonLabel() }}
      </button>
    </div>
  </div>
</div>
