import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss']
})
export class ProgressCircleComponent implements OnInit {

  @Input()
  public timeLeft: number;

  @Input()
  public hide = false;

  @Input()
  public label = '';

  public animationLeft: string;

  public animationRight: string;

  constructor() { }

  ngOnInit(): void {
    const halfTime = this.timeLeft / 2;
    this.animationLeft = `loading-5 ${halfTime}s linear forwards ${halfTime}s`;
    this.animationRight = `loading-5 ${halfTime}s linear forwards`;
  }

}
