import { Injectable } from '@angular/core';
import { SessionKeys } from '@shared/domain/session-keys';
import { GeneralConfigService } from '@shared/services/general-config-service';
import { InvokerService, SessionStorageHandler, LocalStorageHandler } from 'cob-infrastructure';
import * as fakeResponseProducts from './fake-response-products';
import * as fakeResponseLanding from './fake-response-landing';
import * as fakeResponseLegals from './fake-response-legals';
import { CompareCards } from '@shared/enums/product-card.enum';
import { Analytics } from 'fif-common-analytics';

@Injectable()
export class ContentfulService {
  intent = 6;

  constructor(
    private service: InvokerService,
    private generalConfigService: GeneralConfigService,
    private sessionStorageHandler: SessionStorageHandler,
    private taggingAdapter: Analytics.TaggingAdapter,
    private localStorageHandler: LocalStorageHandler,
  ) {
  }

  // tslint:disable-next-line

  public async legalContent() {
    const legalResponse = await this.getContentContenful('service_content_ful_legal_terms', fakeResponseLegals);
    this.saveContentful(SessionKeys.CONTENT, legalResponse);
    return legalResponse;
  }

  public async dataDogConfig() {
    const dataDogConfig = await this.getContentContenful('service_content_ful_data_dog_config', {});
    return dataDogConfig;
  }

  public async productsContent() {
    const productsResponse = await this.getContentContenful('service_content_ful_products', fakeResponseProducts);
    this.saveContentful(SessionKeys.PRODUCTS, productsResponse);
    return productsResponse;
  }

  public async getLandingContentful() {
    return await this.getContentContenful('service_content_ful_landing', fakeResponseLanding);
  }

  private getContentContenful = async (contenfulUrl, fakeResponse) => {
    const dummyValue = {};
    const session = this.localStorageHandler.getValue(SessionKeys.FLOWSESSIONID);
    try {
      if (this.intent > 0) {
        this.intent = this.intent - 1;
        const response = await this.service.post(
          dummyValue,
          this.generalConfigService.getApiUrl(contenfulUrl),
          { class: 'ContentfulService', method: 'sendToContent' },
          { timeout: 3000 }, false,
        );
        return response;
      } else {
        this.taggingAdapter.tagEvent('error', 'ContentfulService', 'getContentContenful-intent', null);
        // En caso de no poder conectar con contentfull, es preferible usar contenido desactualizado. Evaluar y quizá cambiar.
        return fakeResponse.default;
      }
    } catch (e) {
      this.taggingAdapter.tagEvent('error', 'ContentfulService', 'getContentContenful-cath', null);
      // En caso de no poder conectar con contentfull, es preferible usar contenido desactualizado. Evaluar y quizá cambiar.
      return fakeResponse.default;
    }
  };

  getContent(content) {
    try {
      return JSON.parse(this.sessionStorageHandler.getValue(content));
    } catch {
      return undefined;
    }
  }

  public getProductsCard() {
    const content = this.getContent(SessionKeys.PRODUCTS).content;
    const order = {MASTERCARD: 1, BLACK: 2};
    let creditsCars = content.filter(product => (Object.keys(CompareCards).includes(product.productName))) || [];
    creditsCars.sort((a, b) => order[a.productName] - order[b.productName])
    return creditsCars;
  }

  public getProductsContent = () => {
    const productFilter = ['TC', 'CA', 'PAC'];
    const content = this.getContent(SessionKeys.PRODUCTS).content;
    const products = content.filter((element) => {
      return element.hasOwnProperty('productName');
    });

    products.sort((a, b) => {
      return productFilter.indexOf(a.productName) - productFilter.indexOf(b.productName);
    });
    return products;
  };

  public getFakeContent = () => {
    return [...fakeResponseLanding.default, ...fakeResponseProducts.default, ...fakeResponseLegals.default];
  };

  saveContentful(content, responseContent) {
    this.sessionStorageHandler.setValue(content, JSON.stringify({ content: responseContent }));
  }
}
