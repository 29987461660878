import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.css'],
})
export class ModalLoaderComponent implements OnInit {
  public showModal: boolean = false;
  @Input()
  public withText: boolean = false;
  @Input()
  public text: any = {
    title: '',
    description: ''
  };
  staticAssetsUrl = environment.staticAssetsUrl;

  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  show() {
    this.showModal = true;
  }

  hidden() {
    this.showModal = false;
  }
}
