import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionStorageHandler } from 'cob-infrastructure';

@Injectable()
export class GlobalAuthGuard implements CanActivate {
  constructor(private router: Router, private sessionStorageHandler: SessionStorageHandler) { }

  canActivate() {
    if (!this.sessionStorageHandler.getValue('username')) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
