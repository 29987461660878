import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { HttpServiceModule } from 'cob-infrastructure';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';
import localeCO from '@angular/common/locales/es-CO'
import { DatadogService } from "@shared/services/datadog.service";


registerLocaleData(localeCO);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CoreModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    RouterModule,
    BrowserAnimationsModule,
    HttpServiceModule.forRoot()
  ],
  providers: [DatadogService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
